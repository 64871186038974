import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, limit, getCountFromServer, updateDoc, writeBatch, getAggregateFromServer, sum } from 'firebase/firestore'
import Select from 'react-select'
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

function Roomservices() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const printRef = useRef();
    const printRef1 = useRef();

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    })

    const handleKotPrint = useReactToPrint({
        content: () => printRef1.current,
    })

    const [loadincount, setloadincount] = useState(true);
    const [username, setusername] = useState("");

    const [billtype, setbilltype] = useState("Room Posting");
    const [note, setnote] = useState("");

    const [bookings, setbookings] = useState([]);
    const [bookingId, setbookingId] = useState("");

    const [userData, setUserData] = useState();
    const [productCat, setproductCat] = useState([]);
    const [products, setproducts] = useState([]);
    const [orderitems, setorderitems] = useState([]);
    const [printorderitems, setprintorderitems] = useState([]);
    const [staffs, setstaffs] = useState([]);
    const [paymodes, setpaymodes] = useState([]);

    const [name, setname] = useState("");
    const [mobile, setmobile] = useState("");
    const [address, setaddress] = useState("");

    const [orderDocid, setorderDocid] = useState("");
    const [nofopax, setnofopax] = useState("");
    const [tablename, settablename] = useState("");
    const [orderdate, setorderdate] = useState("");
    const [custid, setcustid] = useState("");
    const [customer, setcustomer] = useState("");
    const [staffid, setstaffid] = useState("");
    const [staff, setstaff] = useState("");
    const [billno, setbillno] = useState("");
    const [tablebillno, settablebillno] = useState("");
    const [subtotal, setsubtotal] = useState(0);
    const [tax, settax] = useState(0);
    const [servicecharge, setservicecharge] = useState(0);
    const [totalamount, settotalamount] = useState(0);
    const [roundoff, setroundoff] = useState(0);
    const [discount, setdiscount] = useState(0);
    const [paid, setpaid] = useState(0);
    const [remaining, setremaining] = useState(0);
    const [paytype, setpaytype] = useState("");
    const [item, setitem] = useState("");

    const [removeNote, setremoveNote] = useState("");
    const [deleteItems, setdeleteItems] = useState([]);
    const [updateQtys, setupdateQtys] = useState([]);
    const [itemReady, setitemReady] = useState("Yes");
    const [newQty, setnewQty] = useState(0);

    const [openfoodname, setopenfoodname] = useState("");
    const [openfoodqty, setopenfoodqty] = useState(0);
    const [openfoodrate, setopenfoodrate] = useState(0);
    const [openfoodtotal, setopenfoodtotal] = useState(0);

    const [itemdesc, setitemdesc] = useState("");
    const [itemid, setitemid] = useState("");

    const [noofpersoninrooms, setnoofpersoninrooms] = useState(0);
    const [noofqtyrooms, setnoofqtyrooms] = useState(0);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fetchUsersData()
        });
    });

    const fetchUsersData = async () => {
        try {
            if (userData) {
                if (loadincount) {
                    const docRef = doc(db, "users", userData.uid)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        setusername(docSnap.data().username);
                        setloadincount(false)
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        finaltotal()
        fetchData1();
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setorderdate(formattedDate);
    }, []);


    const fetchData1 = async () => {
        try {

            const q66 = query(collection(db, 'restopaymodes'))
            onSnapshot(q66, (querySnapshot) => {
                setpaymodes(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });

            const q2 = query(collection(db, 'productcats'))
            onSnapshot(q2, (querySnapshot) => {
                setproductCat(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });

            const q3 = query(collection(db, 'products'), orderBy("prodname", "asc"))
            onSnapshot(q3, async (querySnapshot1) => {
                const combinedData1 = [];
                let rohan = 0;
                for (const doc1 of querySnapshot1.docs) {
                    rohan++;
                    combinedData1.push({
                        id: doc1.id,
                        data: doc1.data(),
                        unit: doc1.data().unitname,
                        label: rohan + ". " + doc1.data().prodname,
                        value: doc1.id,
                    });
                }
                setproducts(combinedData1);
            });

            const q7 = query(collection(db, 'staffs'))
            onSnapshot(q7, (querySnapshot) => {
                setstaffs(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data(),
                    label: doc.data().name,
                    value: doc.id,
                })));
            });

            const q8 = query(collection(db, 'bookings'), orderBy("checkindate", "asc"), where('status', '==', 'Booked'))
            onSnapshot(q8, async (querySnapshot1) => {
                const combinedData1 = [];
                let noofperson = 0;
                let noofrooms = 0;
                for (const doc1 of querySnapshot1.docs) {
                    noofperson = parseFloat(noofperson) + parseFloat(doc1.data().adults);
                    noofrooms = parseFloat(noofrooms) + parseFloat(doc1.data().rooms.length);
                    for (let i = 0; i < doc1.data().rooms.length; i++) {
                        combinedData1.push({
                            id: doc1.id,
                            data: doc1.data(),
                            roomno: doc1.data().rooms[i].roomname,
                        });
                    }
                }
                setnoofqtyrooms(noofrooms);
                setnoofpersoninrooms(noofperson);
                setbookings(combinedData1);
            });


            const q111 = query(collection(db, 'plans'))
            onSnapshot(q111, (querySnapshot) => {
                if (querySnapshot.size > 0) {
                    let today = moment().format('YYYY-MM-DD');
                    let enddate = querySnapshot.docs[0].data().enddate;
                    if (today > enddate) {
                        window.location.href = "/plan-expired"
                    }

                }
            });

        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const fetchProducts = (prodcatid) => {
        const q3 = query(collection(db, 'products'), where("prodcatid", "==", prodcatid))
        onSnapshot(q3, async (querySnapshot1) => {
            const combinedData = [];
            for (const doc1 of querySnapshot1.docs) {
                combinedData.push({
                    id: doc1.id,
                    data: doc1.data(),
                    unit: doc1.data().unitname,
                    label: doc1.data().prodname + " " + doc1.data().unitname,
                    value: doc1.id,
                });
            }
            setproducts(combinedData);
        });
    }

    const handleSelectItem = (item) => {
        setitem("");
        addOrderItems(item, item.id, item.data.rate)
    };


    const handleSelectItem2 = async (item) => {
        setstaff(item);
        setstaffid(item.id);
    }

    const addbookingId = async (bookingId, rooms) => {

        setbookingId(bookingId);
        settablename(rooms);
        setorderitems([]);
        const orderQuery = query(collection(db, "orders"), where("bookingId", "==", bookingId), where("status", "==", "Booked"));
        const orderSnapshot = await getDocs(orderQuery);
        const orderData = orderSnapshot.docs.map((doc) => doc.data());

        if (orderData.length > 0) {
            setsubtotal(0);
            setservicecharge(0)
            settax(orderData.noofperson);
            setnofopax(0);
            settotalamount(0);
            setbillno(orderData.billno);
            setorderDocid(orderSnapshot.docs[0].id)
            console.log(orderSnapshot.docs[0].id)
            fetchOrderItems(orderSnapshot.docs[0].id);
        }
        else {
            setsubtotal(0);
            setservicecharge(0)
            settax(0);
            setnofopax(0);
            settotalamount(0);
            const coll = collection(db, "orders");
            const snapshot = await getCountFromServer(coll, where("orderdate", "==", orderdate));
            let billno = parseFloat(snapshot.data().count) + 1;
            setbillno(billno);
            fetchOrderItems([]);
        }

        // setstaffid(snapshot.data().staffid);


    }


    // Helper to add or update order items
    const upsertOrderItem = async (orderDocid, item, itemid, rate) => {
        const itemQuery = query(collection(db, "orderitems"), where("itemid", "==", itemid), where("orderid", "==", orderDocid));
        const itemSnapshot = await getDocs(itemQuery);

        let taxamount = parseFloat(rate) * 0.05;
        let taxadded = parseFloat(taxamount) + parseFloat(rate);

        if (itemSnapshot.docs.length === 0) {
            // Add new order item
            await addDoc(collection(db, 'orderitems'), {
                orderid: orderDocid,
                bookingId: bookingId || null,
                nofopax: nofopax || null,
                custid: custid || null,
                staffid: staffid || null,
                customer: customer || null,
                staff: staff || null,
                orderdate: orderdate || null,
                itemdesc: itemdesc,
                tablebillno: tablebillno || null,
                billno: billno || null,
                itemname: item.data.prodname || null,
                kot: item.data.kot || null,
                status: 'Pending',
                complete: 0,
                itemid: itemid || null,
                unitname: item.unit || null,
                quantity: 1,
                rate: parseFloat(rate),
                total: parseFloat(rate),
                taxamount: parseFloat(taxamount),
                taxaddedamount: parseFloat(taxadded),
                uid: userData.uid,
                created: Timestamp.now()
            });
        } else {
            // Update existing order item
            let docRef = itemSnapshot.docs[0];
            let qty = parseFloat(docRef.data().quantity) + 1;
            let total = qty * rate;
            let taxamount = total * 0.05;
            let taxadded = total + taxamount;

            await updateDoc(docRef.ref, {
                quantity: qty,
                total: parseFloat(total),
                taxamount: parseFloat(taxamount),
                taxaddedamount: parseFloat(taxadded),
                updated: Timestamp.now()
            });
        }
    };

    // Main addOrderItems function
    const addOrderItems = async (item, itemid, rate) => {
        try {
            let orderRef;
            if (!orderDocid) {
                orderRef = await addDoc(collection(db, 'orders'), {
                    bookingId: bookingId || null,
                    nofopax: nofopax || null,
                    custid: custid || null,
                    staffid: staffid || null,
                    customer: customer || null,
                    staff: staff || null,
                    orderdate: orderdate || null,
                    tablebillno: tablebillno || null,
                    billno: billno || null,
                    subtotal: parseFloat(subtotal) || 0,
                    tax: parseFloat(tax) || 0,
                    servicecharge: parseFloat(servicecharge) || 0,
                    totalamount: parseFloat(totalamount) || 0,
                    discount: parseFloat(discount) || 0,
                    paid: parseFloat(paid) || 0,
                    remaining: parseFloat(remaining) || 0,
                    paytype: paytype || null,
                    billtype: billtype || null,
                    note: note || null,
                    status: 'Booked',
                    uid: userData.uid,
                    created: Timestamp.now()
                });
                fetchOrderItems(orderRef.id);
                setorderDocid(orderRef.id);
            } else {
                orderRef = doc(db, 'orders', orderDocid);
                await updateDoc(orderRef, {
                    bookingId: bookingId || null,
                    nofopax: nofopax || null,
                    custid: custid || null,
                    staffid: staffid || null,
                    customer: customer || null,
                    staff: staff || null,
                    orderdate: orderdate || null,
                    tablebillno: tablebillno || null,
                    billno: billno || null,
                    subtotal: parseFloat(subtotal) || 0,
                    tax: parseFloat(tax) || 0,
                    servicecharge: parseFloat(servicecharge) || 0,
                    totalamount: parseFloat(totalamount) || 0,
                    discount: parseFloat(discount) || 0,
                    paid: parseFloat(paid) || 0,
                    remaining: parseFloat(remaining) || 0,
                    paytype: paytype || null,
                    billtype: billtype || null,
                    note: note || null,
                    status: 'Booked',
                    uid: userData.uid,
                    updated: Timestamp.now()
                });
                fetchOrderItems(orderDocid);
            }

            await upsertOrderItem(orderRef.id, item, itemid, rate);

            fetchData1();

        } catch (error) {
            console.log('Error adding order items:', error.message);
        }
    };

    const fetchOrderItems = async (orderid) => {
        const q = query(collection(db, "orderitems"), where("orderid", "==", orderid), orderBy('created', 'asc'));

        // Use a single onSnapshot listener to handle all operations
        onSnapshot(q, (querySnapshot) => {
            let allOrderItems = [];
            let printOrderItems = [];
            let sum = 0;
            let tax = 0;

            // Process each document in the query
            querySnapshot.docs.forEach((doc) => {
                const data = doc.data();
                const total = parseFloat(data.total || 0);
                const taxAmount = parseFloat(data.taxamount || 0);

                // Add to order items
                allOrderItems.push({
                    id: doc.id,
                    data: data,
                });

                // Check for KOT status and calculate remaining quantity
                if (data.kot === 'YES') {
                    const remainingQty = parseFloat(data.quantity) - parseFloat(data.complete || 0);
                    printOrderItems.push({
                        id: doc.id,
                        data: data,
                        qty: remainingQty,
                    });
                }

                // Accumulate sum and tax
                sum += total;
                tax += taxAmount;
            });

            // Update order items and print order items in state
            setorderitems(allOrderItems);
            setprintorderitems(printOrderItems);

            // Update tax and subtotal
            settax(tax.toFixed(2));
            const subtotal = sum.toFixed(2);
            setsubtotal(subtotal);

            // Calculate the total amount with service charge and roundoff
            const totalAmount = sum + tax + parseFloat(servicecharge || 0);
            const roundoff = parseFloat((totalAmount.toFixed() - totalAmount).toFixed(2));
            setroundoff(roundoff);

            const finalTotalAmount = (totalAmount + roundoff).toFixed(2);
            settotalamount(finalTotalAmount);

            // Final total processing
            finaltotal();
        });
    }

    const deleteOrderItem = async (docid, itemid, quantity, bookingId, tablebillno, orderid, rate, status) => {
        if (status === 'Active') {
            setdeleteItems([docid, itemid, quantity, bookingId, tablebillno, orderid])
        } else {
            const taskDocRef = doc(db, 'orderitems', docid)
            await deleteDoc(taskDocRef);
            const q1 = query(collection(db, 'orderitems'), where("orderid", "==", orderid));
            onSnapshot(q1, async (querySnapshot) => {
                if (querySnapshot.size === 0) {
                    const taskDoc2Ref = doc(db, 'orders', orderid)
                    await deleteDoc(taskDoc2Ref);
                    setorderDocid("")
                }
            });
            fetchData1()
            fetchOrderItems(orderid, tablebillno, orderid);
        }

    }

    const upOrderQty = async (type, docid, itemid, qty, bookingId, tablebillno, orderid, rate) => {
        if (type === "Remove") {
            const docProdRef1 = doc(db, 'orderitems', docid);
            const docSnap1 = await getDoc(docProdRef1);
            if (docSnap1.exists()) {
                let newstock = parseFloat(docSnap1.data().quantity) - parseFloat(qty);
                if (newstock < 1) {
                    const taskDocRef = doc(db, 'orderitems', docid)
                    await deleteDoc(taskDocRef);
                    const taskDoc2Ref = doc(db, 'orders', orderid)
                    await deleteDoc(taskDoc2Ref);
                    setorderDocid("")
                } else {

                    let tot = parseFloat(newstock) * parseFloat(docSnap1.data().rate);
                    let taxamount = parseFloat(tot) * 5 / 100;
                    let taxadded = parseFloat(taxamount) + parseFloat(tot)

                    await updateDoc(doc(db, 'orderitems', docid), {
                        quantity: newstock,
                        total: parseFloat(tot),
                        taxamount: parseFloat(taxamount) || null,
                        taxaddedamount: parseFloat(taxadded) || null,
                    });
                }
            }

            fetchData1()
            fetchOrderItems(orderid, tablebillno, orderid);
        }
        else {
            const docProdRef1 = doc(db, 'orderitems', docid);
            const docSnap1 = await getDoc(docProdRef1);
            if (docSnap1.exists()) {
                let newstock = parseFloat(docSnap1.data().quantity) + parseFloat(qty);

                let tot = parseFloat(newstock) * parseFloat(docSnap1.data().rate);
                let taxamount = parseFloat(tot) * 5 / 100;
                let taxadded = parseFloat(taxamount) + parseFloat(tot)

                await updateDoc(doc(db, 'orderitems', docid), {
                    quantity: newstock,
                    total: parseFloat(tot),
                    taxamount: parseFloat(taxamount) || null,
                    taxaddedamount: parseFloat(taxadded) || null,
                });


            } else {
                const taskDocRef = doc(db, 'orderitems', docid)
                await deleteDoc(taskDocRef);
            }
            fetchData1()
            fetchOrderItems(orderid, tablebillno, orderid);
        }
    }


    const finaltotal = () => {
        let remainingnew = (parseFloat(totalamount)) - (parseFloat(paid) + parseFloat(discount));
        if (remainingnew < 0) {
            setpaid(0);
            setremaining(totalamount).toFixed(2);
            return;
        }
        if (paid === '') {
            setremaining(totalamount).toFixed(2);
        } else {
            setremaining(remainingnew.toFixed(2));
        }
    }

    const handleSubmit = async () => {
        await addDoc(collection(db, 'restocustomers'), {
            name: name,
            mobile: mobile,
            address: address,
            uid: userData.uid,
            total: 0,
            paid: 0,
            email: "",
            remaining: 0,
            created: Timestamp.now()
        });
        setname(""); setmobile(""); setaddress("");
        fetchData1()
        Toast.fire({
            icon: "success",
            title: 'Credit Account Added Successfully!!'
        });
    }

    const saveBill = async () => {
        try {
            if (!totalamount || !remaining) {
                Toast.fire({ icon: "error", title: 'Please check the total amount, paid & remaining values' });
                return;
            }

            if (billtype === 'Room Posting' && !bookingId) {
                Toast.fire({ icon: "error", title: 'Please select a Room No' });
                return;
            }
            const coll = collection(db, "orders");
            const snapshot = await getCountFromServer(coll, where("orderdate", "==", orderdate));
            let billno = parseFloat(snapshot.data().count) + 1;


            await updateDoc(doc(db, 'orders', orderDocid),
                {
                    status: "Bill Pending",
                    bookingId: bookingId || null,
                    billno: billno || null,
                    paiddate: orderdate || null,
                    subtotal: parseFloat(subtotal) || 0,
                    tax: parseFloat(tax) || 0,
                    servicecharge: parseFloat(servicecharge) || 0,
                    totalamount: parseFloat(totalamount) || 0,
                    discount: parseFloat(discount) || 0,
                    paid: parseFloat(paid) || 0,
                    remaining: parseFloat(remaining) || 0,
                    paytype: paytype || null,
                    billtype: billtype || null,
                    note: note || null,
                });
            // Handle Room Posting logic
            if (billtype === 'Room Posting') {
                const bookingRef = doc(db, "bookings", bookingId);
                const bookingSnap = await getDoc(bookingRef);

                if (bookingSnap.exists()) {
                    const bookingData = bookingSnap.data();
                    const totalResto = parseFloat(totalamount) + parseFloat(bookingData.restoamount || 0);
                    const totalBill = parseFloat(totalamount) + parseFloat(bookingData.totalamount || 0);
                    const totalPaid = parseFloat(paid) + parseFloat(bookingData.advance || 0);
                    const totalRemaining = totalBill - totalPaid;

                    await updateDoc(doc(db, 'bookings', bookingId), {
                        restoamount: totalResto,
                        totalamount: totalBill,
                        advance: totalPaid,
                        remaining: totalRemaining,
                    });
                }
            }


            // Reset fields and refresh data
            setsubtotal(0); setpaid(0); setremaining(0); setorderDocid("");
            setcustid(""); setbillno(""); setpaytype("Cash"); setorderitems([]);
            setbookingId(""); settotalamount(0);
            fetchData1();
            Toast.fire({ icon: "success", title: 'Order Successfully Paid' });
            window.location.href = "";

        } catch (error) {
            Toast.fire({ icon: "error", title: 'Something went wrong. Please try again.' });
            console.log(error.message);
        }
    };

    const handleRemoveItem = async () => {
        if (!removeNote) {
            Toast.fire({ icon: "error", title: 'Please enter note' });
            return
        }

        let itemname = "";
        if (deleteItems[1]) {
            const docProdRef = doc(db, 'products', deleteItems[1]);
            const docSnap = await getDoc(docProdRef);
            itemname = docSnap.data().prodname;
        }
        else {
            itemname = "";
        }

        const docProdRef1 = doc(db, 'seatings', deleteItems[3]);
        const docSnap1 = await getDoc(docProdRef1);

        await addDoc(collection(db, 'rejectionnote'), {
            removeNote: removeNote || null,
            docid: deleteItems[0],
            itemid: deleteItems[1],
            quantity: deleteItems[2],
            bookingId: deleteItems[3],
            tablebillno: deleteItems[4],
            orderid: deleteItems[5],
            itemname: itemname,
            tableno: docSnap1.data().seatname,
            uid: userData.uid,
            created: Timestamp.now()
        });

        const taskDocRef = doc(db, 'orderitems', deleteItems[0])
        await deleteDoc(taskDocRef);
        const q1 = query(collection(db, 'orderitems'), where("orderid", "==", deleteItems[5]));
        onSnapshot(q1, async (querySnapshot) => {
            if (querySnapshot.size === 0) {
                const taskDoc2Ref = doc(db, 'orders', deleteItems[5])
                await deleteDoc(taskDoc2Ref);
                setorderDocid("")
            }
        });

        fetchData1()
        fetchOrderItems(deleteItems[3], deleteItems[4], deleteItems[5]);
        Toast.fire({ icon: "success", title: 'Order Items Sucessfully Removed' });
    }

    const handleUpdateItem = async () => {
        try {
            const isRemoving = updateQtys[0] === "Remove";
            const itemDocRef = doc(db, 'orderitems', updateQtys[1]);

            if (isRemoving && parseFloat(newQty) > parseFloat(updateQtys[3])) {
                Toast.fire({ icon: "error", title: 'Item Quantity should not be greater than ' + updateQtys[3] });
                return;
            }

            if (isRemoving && !removeNote) {
                Toast.fire({ icon: "error", title: 'Please enter a note' });
                return;
            }

            const docSnap = await getDoc(itemDocRef);

            if (docSnap.exists()) {
                let currentQty = parseFloat(docSnap.data().quantity);
                let newQtyValue = isRemoving ? currentQty - parseFloat(newQty) : currentQty + parseFloat(newQty);

                const batch = writeBatch(db);

                if (newQtyValue < 1) {
                    batch.delete(itemDocRef); // Delete order item
                    batch.delete(doc(db, 'orders', updateQtys[6])); // Delete order
                    setorderDocid("");
                } else {
                    let totallll = parseFloat(newQtyValue) * parseFloat(updateQtys[7])
                    // Update order item quantity and total
                    batch.update(itemDocRef, {
                        custid: custid,
                        customer: customer,
                        quantity: newQtyValue,
                        propid: removeNote || null,
                        total: parseFloat(totallll),
                    });
                }
                // Mark item as complete if required
                if (itemReady === 'Yes') {
                    batch.update(itemDocRef, { complete: parseFloat(updateQtys[3]) });
                }
                // Commit batch operations
                await batch.commit();
                // Refresh data after operation
                fetchData1();
                fetchOrderItems(bookingId, tablebillno, updateQtys[6]);

                // Reset state
                setupdateQtys([]);
                setitemReady("Yes");
                setnewQty(0);

                Toast.fire({ icon: "success", title: 'Order Items Successfully Updated' });
            } else {
                Toast.fire({ icon: "error", title: 'Item does not exist' });
            }
        } catch (error) {
            console.log("Error updating item: ", error.message);
        }
    };


    const calOpenFood = () => {
        let total = parseFloat(openfoodqty) * parseFloat(openfoodrate)
        setopenfoodtotal(total)
    }

    const handleServiceCharge = (e) => {
        setservicecharge(e.target.value);
        if (e.target.value) {
            let total = parseFloat(subtotal) + parseFloat(tax) + parseFloat(e.target.value);
            settotalamount(total.toFixed());
            let rem = parseFloat(total) - parseFloat(paid) - parseFloat(discount);
            setremaining(rem.toFixed(2))
        }
    }

    const handleDiscount = (e) => {
        setdiscount(e.target.value);
        if (e.target.value) {
            let total = parseFloat(totalamount) - parseFloat(e.target.value);
            let rem = parseFloat(total) - parseFloat(paid);
            setremaining(rem.toFixed(2))
        }
    }

    const handlePaid = (e) => {
        setpaid(e.target.value);
        if (e.target.value) {
            let total = parseFloat(totalamount) - parseFloat(discount) - parseFloat(e.target.value);
            setremaining(total.toFixed(2))
        }
    }

    const addOpenFood = async () => {
        if (!openfoodname || !openfoodqty || !openfoodrate || !openfoodtotal) {
            Toast.fire({ icon: "error", title: 'Please fill all the fields' });
            return;
        }

        try {
            let orderRef = orderDocid ? doc(db, 'orders', orderDocid) : null;

            // If no orderDocId exists, create a new order and get its reference
            if (!orderRef) {
                orderRef = await addDoc(collection(db, 'orders'), {
                    bookingId: bookingId || null,
                    nofopax: nofopax || null,
                    custid: custid || null,
                    staffid: staffid || null,
                    customer: customer || null,
                    staff: staff || null,
                    orderdate: orderdate || null,
                    tablebillno: tablebillno || null,
                    billno: billno || null,
                    subtotal: parseFloat(subtotal) || null,
                    tax: parseFloat(tax) || null,
                    servicecharge: parseFloat(servicecharge) || null,
                    totalamount: parseFloat(totalamount) || null,
                    discount: parseFloat(discount) || null,
                    paid: parseFloat(paid) || null,
                    remaining: parseFloat(remaining) || null,
                    paytype: paytype || null,
                    billtype: billtype || null,
                    note: note || null,
                    status: 'Booked',
                    uid: userData.uid,
                    created: Timestamp.now()
                });
                setorderDocid(orderRef.id); // Save the order ID for future updates
            }

            // Check if the food item already exists in orderitems
            const existingFoodQuery = query(
                collection(db, 'orderitems'),
                where('itemname', '==', openfoodname),
                where('orderid', '==', orderRef.id)
            );

            const existingFoodSnapshot = await getDocs(existingFoodQuery);

            const batch = writeBatch(db);

            if (existingFoodSnapshot.empty) {
                const newItem = {
                    orderid: orderRef.id,
                    bookingId: bookingId || null,
                    nofopax: nofopax || null,
                    custid: custid || null,
                    staffid: staffid || null,
                    customer: customer || null,
                    staff: staff || null,
                    orderdate: orderdate || null,
                    itemdesc: itemdesc || null,
                    tablebillno: tablebillno || null,
                    billno: billno || null,
                    itemname: openfoodname || null,
                    status: 'Pending',
                    complete: 0,
                    quantity: openfoodqty || 1,
                    rate: openfoodrate || null,
                    total: parseFloat(openfoodtotal),
                    taxamount: 0,
                    taxaddedamount: parseFloat(openfoodtotal),
                    uid: userData.uid,
                    created: Timestamp.now()
                };

                batch.set(doc(collection(db, 'orderitems')), newItem);
            } else {
                // Update existing food item quantity and total
                const existingItem = existingFoodSnapshot.docs[0];
                const updatedQuantity = parseFloat(existingItem.data().quantity) + 1;
                const updatedTotal = parseFloat(updatedQuantity) * parseFloat(openfoodrate);
                const updateData = {
                    quantity: updatedQuantity,
                    total: updatedTotal.toFixed(2),
                    taxaddedamount: updatedTotal.toFixed(2), // Update taxaddedamount accordingly
                };

                batch.update(existingItem.ref, updateData);
            }

            // Commit batch operation for updating or adding items
            await batch.commit();

            // Clear form fields after the operation
            setopenfoodname("");
            setopenfoodqty(0);
            setopenfoodrate(0);
            setopenfoodtotal(0);

            // Fetch updated order items and data
            fetchOrderItems(orderRef.id, tablebillno, orderRef.id);
            fetchData1(); // Refresh order data

        } catch (error) {
            console.log("Error adding food: ", error.message);
        }
    };

    const addRemark = async () => {
        await updateDoc(doc(db, 'orderitems', itemid), {
            itemdesc: itemdesc,
        });

        Toast.fire({ icon: "success", title: 'Item Remark Successfully Added' });
        setitemdesc("");
        fetchData1()

    }

    const saveOrderItems = async () => {
        try {
            const updateData = {
                status: 'Active',
            };
            const q = query(collection(db, "orderitems"), where("orderid", "==", orderDocid));
            const querySnapshot = await getDocs(q);
            const batch = writeBatch(db);
            querySnapshot.forEach((doc) => {
                batch.update(doc.ref, updateData);
            });
            await batch.commit();
            fetchData1()
            Toast.fire({ icon: "success", title: 'Order Successfully Saved' });
        } catch (error) {
        }

    }

    const cancleOrder = async () => {

        await updateDoc(doc(db, 'orders', orderDocid), {
            status: "Cancled",
        });
        setbookingId("");
        settablename("");
        setsubtotal(0);
        settax(0);
        setservicecharge(0)
        settotalamount(0);
        setnofopax(0)
        fetchData1()
        Toast.fire({ icon: "success", title: 'Order Successfully Cancled' });
    }



    return (
        <div>
            <div className="content-wrapper" style={{ backgroundColor: '#fff' }}>
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ textTransform: "uppercase" }} >|| Live Bookings ||</button>
                        <div className="card card-primary card-outline mt-2">
                            <div className="card-body table-responsive p-0" >

                                <table className="table table-bordered table-hover table-head-fixed">
                                    <thead>
                                        <tr>
                                            <td colSpan={4}><b>Total</b></td>
                                            <td><b>{noofqtyrooms} Rooms</b></td>
                                            <td><b>{noofpersoninrooms} Guests</b></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Customer Name </th>
                                            <th>Checkin Date </th>
                                            <th>Checkout Date </th>
                                            <th>Room No </th>
                                            <th>Guests </th>
                                            <th style={{ textAlign: "right" }}>Food Amount </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            bookings.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>

                                                    <td style={{ padding: 0 }}><a href='#0' className='form-control' style={{ textTransform: "capitalize", fontSize: 20, color: "black", fontWeight: "700", border: "none" }} onClick={() => addbookingId(item.id, item.roomno)} data-toggle="modal" data-target="#addorder-modal">{item.data.customers.custname}</a></td>
                                                    <td>{moment(item.data.checkindate).format('DD/MM/YYYY')}</td>
                                                    <td>{moment(item.data.checkoutdate).format('DD/MM/YYYY')}</td>
                                                    {/* <td>
                                                        <div className='row'>
                                                            {item.data.rooms.map((item1, index) =>
                                                                <span>{item1.roomname} &nbsp;  </span>
                                                            )}
                                                        </div>
                                                    </td> */}
                                                    <td>{item.roomno} </td>
                                                    <td>{item.data.adults} Persons</td>
                                                    <td style={{ textAlign: "right" }}>{parseFloat(item.data.restoamount).toFixed(2)} </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* ======================== Modals  ====================================================== */}
            <div className="modal fade" id="addorder-modal">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#F9F7F7", padding: 10 }}>
                            <h5 className="modal-title" style={{ color: "black" }}>Room Orders </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                            <div style={{ float: "right" }}>
                                <button onClick={() => { if (window.confirm('Are u sure cancle?')) { cancleOrder() }; }} className=' btn btn-info btn-danger'>Cancle Order </button>&nbsp;
                                <button onClick={saveOrderItems} className=' btn btn-info btn-default bg-pink'>Save </button>&nbsp;
                                <button onClick={handleKotPrint} className=' btn btn-info'>KOT Print  <i className='fa fa-print'></i> </button>&nbsp;
                                <button onClick={handlePrint} className=' btn bg-purple'>Preview Bill   </button>&nbsp;
                                <button onClick={() => finaltotal()} data-toggle="modal" data-target="#bill-modal" className=' btn btn-success'>Settlement</button>&nbsp;
                                <button className=' btn btn-warning' data-dismiss="modal" aria-label="Close">Home  </button>&nbsp;
                            </div>
                        </div>
                        <div className="modal-body p-1">
                            <div className='row'>
                                <div className='col-sm-2 mobile-hide'>
                                    <div className='card' style={{ height: "100%", backgroundColor: "#F9F7F7" }}>
                                        <div className='card-body'>
                                            {
                                                productCat.map((item, index) =>
                                                    <button key={index} onClick={() => fetchProducts(item.id)} style={{ textTransform: "uppercase", textAlign: "left", fontSize: 14 }} className='form-control btn btn-info mt-1'>{item.data.catname}</button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='card' style={{ height: "100%", backgroundColor: "#F9F7F7" }}>
                                        <div className='card-body'>
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <label>Select Staff</label>
                                                    <Select options={staffs} value={staff} onChange={handleSelectItem2} />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label>No Of Person</label>
                                                    <input type="text" className='form-control' value={nofopax} onChange={(e) => setnofopax(e.target.value)} />
                                                </div>
                                            </div><hr />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <label> Open Food Name</label>
                                                    <input type="text" value={openfoodname} onChange={(e) => setopenfoodname(e.target.value)} className="form-control" />
                                                </div>
                                                <div className="col-sm-2">
                                                    <label>Rate</label>
                                                    <input type="text" value={openfoodrate} onChange={(e) => setopenfoodrate(e.target.value)} className="form-control" onKeyUp={calOpenFood} />
                                                </div>
                                                <div className="col-sm-2">
                                                    <label>Quantity</label>
                                                    <input type="number" value={openfoodqty} onChange={(e) => setopenfoodqty(e.target.value)} className="form-control" onKeyUp={calOpenFood} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <label>Total</label>
                                                    <input type="text" value={openfoodtotal} onChange={(e) => setopenfoodtotal(e.target.value)} className="form-control" onKeyUp={calOpenFood} />
                                                </div>
                                                <div className="col-sm-2">
                                                    <br />
                                                    <button className='btn btn-success btn-sm form-control mt-2' onClick={addOpenFood}>Add </button>
                                                </div>
                                            </div><br />
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <label>Search Product</label>
                                                    <Select options={products} value={item} onChange={handleSelectItem} /><hr />
                                                </div>
                                            </div>
                                            <div className='row mobile-hide'>
                                                {
                                                    products.map((item, index) =>
                                                        <div key={index} className='col-sm-3'>
                                                            <button onClick={() => addOrderItems(item, item.id, item.data.rate)} style={{ fontSize: 11, padding: 0 }} className='form-control btn btn-secondary mt-1'> {item.data.prodname} {item.unit}</button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='card' style={{ height: "100%" }}>
                                        <div className='card-body'>
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <label>Bill No : {billno}</label>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label style={{ float: "right" }}>Date No : {moment(orderdate).format('DD/MM/YYYY')}</label>
                                                </div>
                                            </div>
                                            <div className='productBox '>
                                                <table className='customers3'>
                                                    {
                                                        orderitems.map((item, index) =>
                                                            <tr key={index}>
                                                                <td>
                                                                    <a href='#0' data-toggle="modal" data-target="#itemdesc-modal" onClick={() => setitemid(item.id)}>
                                                                        {item.data.itemname} <br /> <span style={{ color: "#454545" }}>{item.data.itemdesc}</span>
                                                                    </a>
                                                                </td>
                                                                <td><button onClick={() => upOrderQty('Remove', item.id, item.data.itemid, 1, item.data.bookingId, item.data.tablebillno, item.data.orderid, item.data.rate)} className='btn btn-xs btn-info'><i className='fa fa-minus'></i></button></td>
                                                                <td>{item.data.quantity}</td>
                                                                <td style={{ textAlign: "right" }}><button onClick={() => upOrderQty('Add', item.id, item.data.itemid, 1, item.data.bookingId, item.data.tablebillno, item.data.orderid, item.data.rate)} className='btn btn-xs btn-info'><i className='fa fa-plus'></i></button></td>
                                                                <td style={{ textAlign: "right" }}>{item.data.total.toFixed(2)}</td>
                                                                {
                                                                    item.data.status === 'Active' ?
                                                                        <td style={{ textAlign: "right" }}><button data-toggle="modal" data-target="#reject-modal" onClick={() => deleteOrderItem(item.id, item.data.itemid, item.data.quantity, item.data.bookingId, item.data.tablebillno, item.data.orderid, item.data.rate, item.data.status)} className='btn btn-xs btn-danger'><i className='fa fa-trash'></i></button></td> :
                                                                        <td style={{ textAlign: "right" }}><button onClick={() => deleteOrderItem(item.id, item.data.itemid, item.data.quantity, item.data.bookingId, item.data.tablebillno, item.data.orderid, item.data.rate, item.data.status)} className='btn btn-xs btn-danger'><i className='fa fa-trash'></i></button></td>
                                                                }
                                                            </tr>
                                                        )
                                                    }
                                                </table>

                                            </div><hr />
                                            <div className='col-sm-12'>
                                                <h6 style={{ marginBottom: 10 }}>Sub Total :  <span style={{ float: "right" }}>Rs. {subtotal}</span> </h6>
                                                <h6 style={{ marginBottom: 10 }}>Tax :  <span style={{ float: "right" }}>Rs. {tax}</span> </h6>
                                                <h6 style={{ marginBottom: 10 }}>Round Off :  <span style={{ float: "right" }}>Rs. {roundoff}</span> </h6>
                                                <h6 style={{ marginBottom: 10 }}>Total Amount :  <span style={{ float: "right" }}>Rs. {totalamount}</span> </h6>
                                            </div><hr />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="bill-modal">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Create Bill {tablename}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className='row '>
                                <div className="col-sm-3">
                                    <label>Bill No </label>
                                    <input type="text" className="form-control" style={{ height: 35, fontSize: 14 }} value={billno} onChange={(e) => setbillno(e.target.value)} />
                                </div>
                                <div className="col-sm-3">
                                    <label>Bill Date </label>
                                    <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={orderdate} onChange={(e) => setorderdate(e.target.value)} />
                                </div>
                                <div className="col-sm-3">
                                    <label>Note </label>
                                    <textarea className="form-control" value={note} onChange={(e) => setnote(e.target.value)} style={{ height: 35, fontSize: 14 }}></textarea>
                                </div>
                            </div><br />
                            <table className='table table-bordered'>
                                <tr>
                                    <th>Item Name</th>
                                    <th>Quantity</th>
                                    <th>Rate</th>
                                    <th>Tax</th>
                                    <th>Total</th>
                                </tr>
                                {
                                    orderitems.map((item, index) =>
                                        <tr key={index}>
                                            <td>{item.data.itemname}  </td>
                                            <td>{item.data.quantity}</td>
                                            <td>{item.data.rate}</td>
                                            <td>{item.data.taxamount}</td>
                                            <td>{item.data.taxaddedamount}/-</td>
                                        </tr>
                                    )
                                }
                            </table>
                            <div className='row '>
                                <div className='col-sm-3'>
                                    <label>Sub Total </label>
                                    <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} readOnly value={subtotal} />
                                </div>
                                <div className='col-sm-3'>
                                    <label>Tax </label>
                                    <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} readOnly value={tax} />
                                </div>
                                <div className='col-sm-3'>
                                    <label>Service Charge </label>
                                    <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} value={servicecharge} onChange={handleServiceCharge} />
                                </div>
                                <div className='col-sm-3'>
                                    <label>Total Amount </label>
                                    <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} readOnly value={totalamount} />
                                </div>
                                <div className="col-sm-3">
                                    <label>Discount</label>
                                    <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} const value={discount} onChange={handleDiscount} placeholder='Enter Paid Amount' />
                                </div>
                                <div className="col-sm-3">
                                    <label>Paid</label>
                                    <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} value={paid} onChange={handlePaid} placeholder='Enter Paid Amount' />
                                </div>
                                <div className="col-sm-3">
                                    <label>Payment Type</label>
                                    <select className='form-control' style={{ height: 35, fontSize: 14 }} value={paytype} onChange={(e) => setpaytype(e.target.value)}>
                                        <option></option>
                                        {
                                            paymodes.map((item, index) =>
                                                <option key={index} value={item.data.paymode}>{item.data.paymode}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-sm-3">
                                    <label>Remaining</label>
                                    <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} value={remaining} readOnly />
                                </div>
                            </div><br></br>
                            <center>
                                <button onClick={() => saveBill()} className='btn btn-info'>Save Bill</button>
                            </center>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="customer-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Credit Account</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>  Account Name</label>
                                    <input type="text" value={name} onChange={(e) => setname(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label>Mobile</label>
                                    <input type="text" value={mobile} onChange={(e) => setmobile(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6 mt-2">
                                    <label>Address</label>
                                    <input type="text" value={address} onChange={(e) => setaddress(e.target.value)} className="form-control" />
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="bill-modal">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Print Bill {tablename}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div style={{ padding: 20 }} ref={printRef}>
                                <div className='col-sm-4'>
                                    <center>
                                        <h3 style={{ textTransform: "uppercase" }}>THE SKYLAND  </h3>
                                        <span style={{ fontSize: 14, color: "black" }}>Near New Bus Stand, Latur Road, Tuljapur, Dist. Dharashiv, 413 601</span><br />
                                        <span style={{ fontSize: 14, color: "black" }}>Mobile No: 7077779163, 7077779164, 7077779165 </span><br />
                                        <span style={{ fontSize: 14, color: "black" }}>GSTIN No: 27AAWFP8677Q1ZN </span><br />
                                    </center>
                                    <br />
                                    <table width="100%">
                                        <tr>
                                            <td style={{ color: "black", fontSize: 14 }}>Bill No : {billno} </td>
                                            <td style={{ textAlign: "right", color: "black", fontSize: 14 }}>Room No : {tablename}</td>
                                        </tr>
                                        <tr >
                                            <td style={{ color: "black", fontSize: 14 }}>Date :  {moment(orderdate).format('DD/MM/YYYY')} </td>
                                            <td style={{ textAlign: "right", color: "black", fontSize: 14 }}>Time :  {moment().format('HH:mm:ss A')} </td>
                                        </tr>
                                        <tr >
                                            <td colSpan={2} style={{ color: "black", fontSize: 14 }}>No of Person :  {nofopax} </td>
                                        </tr>
                                        <tr >
                                            <td style={{ color: "black", fontSize: 14 }}>Guest Name  :  {customer.name} </td>
                                            <td style={{ textAlign: "right", color: "black", fontSize: 14 }}>Mobile  :  {customer.mobile} </td>
                                        </tr>
                                    </table>
                                    <table className='billtables' width="100%" style={{ marginTop: 2 }}>
                                        <tr>
                                            <th>Item Name</th>
                                            <th style={{ fontSize: 14, color: "black", textAlign: "right" }}>Qty</th>
                                            <th style={{ fontSize: 14, color: "black", textAlign: "right" }}>Rate</th>
                                            <th style={{ fontSize: 14, color: "black", textAlign: "right" }}>Total</th>
                                        </tr>
                                        {
                                            orderitems.map((item, index) =>
                                                <tr key={index}>
                                                    <td>{item.data.itemname}  </td>
                                                    <td style={{ fontSize: 14, color: "black", textAlign: "right" }}>{item.data.quantity}</td>
                                                    <td style={{ fontSize: 14, color: "black", textAlign: "right" }}>{item.data.rate}</td>
                                                    <td style={{ fontSize: 14, color: "black", textAlign: "right" }}>{parseFloat(item.data.total).toFixed(2)}</td>
                                                </tr>
                                            )
                                        }
                                    </table>
                                    <span style={{ fontSize: 14, marginBottom: 10, color: "black", marginTop: 5 }}>Sub Total :  <span style={{ float: "right" }}> {subtotal}</span> </span><br />
                                    <span style={{ fontSize: 14, marginBottom: 10, color: "black" }}>Cgst (2.5%) :  <span style={{ float: "right" }}> {tax / 2}</span> </span><br />
                                    <span style={{ fontSize: 14, marginBottom: 10, color: "black" }}>Sgst (2.5%) :  <span style={{ float: "right" }}> {tax / 2}</span> </span><br />
                                    <span style={{ fontSize: 14, marginBottom: 10, color: "black" }}>Total Amount :  <span style={{ float: "right" }}> {totalamount}</span> </span><br />
                                    <hr />
                                    <center>
                                        <h6>Thank You</h6>
                                    </center>
                                    <span style={{ fontSize: 14, color: "black" }}>Prepared By  : {username}</span><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="bill-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">KOT PRINT {tablename}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            {/* ==================================== Print Bill ==================================== */}

                            <div style={{ padding: 20 }} ref={printRef1}>
                                <div className='col-sm-4'>
                                    <center>
                                        <h3 style={{ textTransform: "uppercase", fontWeight: "700" }}>THE SKYLAND  </h3>
                                    </center>
                                    <table width="100%">
                                        <tr>
                                            <td style={{ color: "black", fontSize: 16 }}>Bill No : {billno} </td>
                                            <td style={{ textAlign: "right", color: "black", fontSize: 16 }}>Room No : {tablename}</td>
                                        </tr>
                                        <tr >
                                            <td style={{ color: "black", fontSize: 16 }}>Date :  {moment(orderdate).format('DD/MM/YYYY')} </td>
                                            <td style={{ textAlign: "right", color: "black", fontSize: 16 }}>Time :  {moment().format('HH:mm:ss A')} </td>
                                        </tr>
                                        <tr >
                                            <td colSpan={2} style={{ color: "black", fontSize: 16 }}>No of Person :  {nofopax} </td>
                                        </tr>
                                        <tr >
                                            <td colSpan={2} style={{ color: "black", fontSize: 16 }}>Printed By :  {username} </td>
                                        </tr>
                                    </table>
                                    <table width="100%" className='billtables' style={{ marginTop: 2 }}>
                                        <tr>
                                            <th style={{ fontSize: 18 }}>Item Name</th>
                                            <th style={{ textAlign: "right", fontSize: 18 }}>Qty</th>
                                        </tr>
                                    </table>
                                    <table width="100%">
                                        {
                                            printorderitems.map((item, index) =>
                                                <tr key={index}>
                                                    <td style={{ fontSize: 20 }}>{item.data.itemname} <br /> <i style={{ color: "#454545" }}>{item.data.itemdesc}</i>  </td>
                                                    <td style={{ textAlign: "right", fontSize: 20, verticalAlign: "top" }}>{item.qty}</td>
                                                </tr>
                                            )
                                        }
                                    </table>
                                    <br />
                                    <br />
                                    <hr />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="reject-modal">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Enter Rejection for Remove Item</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div >
                                <div className='col-sm-12'>
                                    <label>Enter Note</label>
                                    <input className='form-control' value={removeNote} onChange={(e) => setremoveNote(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => handleRemoveItem()} className="btn btn-primary" data-dismiss="modal">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="itemdesc-modal">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Item Remark</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div >
                                <div className='col-sm-12'>
                                    <label>Enter Remark</label>
                                    <input className='form-control' value={itemdesc} onChange={(e) => setitemdesc(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => addRemark()} className="btn btn-primary" data-dismiss="modal">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="qtyupdate-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{updateQtys[0]} Qty</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label>{updateQtys[3]} Item Ready in Kitchen</label>
                                    <select className='form-control' value={itemReady} onChange={(e) => setitemReady(e.target.value)}>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div className='col-sm-4'>
                                    <label>{updateQtys[0]} Qty</label>
                                    <input type='number' className='form-control' value={newQty} onChange={(e) => setnewQty(e.target.value)} />
                                </div>
                                {
                                    updateQtys[0] === 'Remove' &&
                                    <div className='col-sm-4'>
                                        <label>Remove Qty Note</label>
                                        <input type='text' className='form-control' value={removeNote} onChange={(e) => setremoveNote(e.target.value)} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => handleUpdateItem()} className="btn btn-primary" data-dismiss="modal">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensure the overlay is on top
};


export default Roomservices
