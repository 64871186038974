import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import moment from 'moment';
import { collection, query, orderBy, onSnapshot, getDoc, doc, where, limit, updateDoc, Timestamp } from 'firebase/firestore'
import { useReactToPrint } from 'react-to-print';
import Select from 'react-select'

export default function ViewOrders() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [username, setusername] = useState("");

    const [searchText, setsearchText] = useState("");
    const [orders, setorders] = useState([]);
    const [orderdate, setorderdate] = useState("");
    const [billno, setbillno] = useState("");
    const [totalamount, settotalamount] = useState(0);
    const [paytype, setpaytype] = useState("");
    const [subtotal, setsubtotal] = useState(0);
    const [gstamount, setgstamount] = useState(0);
    const [orderitems, setorderitems] = useState([]);
    const [watchreports, setwatchreports] = useState([]);
    const [loadincount, setloadincount] = useState(true);

    const [tablename, settablename] = useState("");
    const [nofopax, setnofopax] = useState("");
    const [custname, setcustname] = useState("");
    const [custmobile, setcustmobile] = useState("");
    const [paymodes, setpaymodes] = useState([]);

    const [updocid, setupdocid] = useState("");
    const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");

    const [bookings, setbookings] = useState([]);
    const [bookingData, setbookingData] = useState("");
    const [bookingId, setbookingId] = useState("");

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            fetchUsersData(user)
        });
    });


    const fetchUsersData = async (userData) => {
        try {
            if (userData) {
                if (loadincount) {
                    const docRef = doc(db, "users", userData.uid)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        setusername(docSnap.data().username);
                        console.log(docSnap.data().username);
                        setloadincount(false)
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const printRef = useRef();
    const printRef1 = useRef();

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    })

    const handlePrint1 = useReactToPrint({
        content: () => printRef.current,
    })

    useEffect(() => {
        if (searchText === '') {
            fetchData1()
        } else {
            fetchData2()
        }
    }, [searchText]);


    const fetchData1 = async () => {
        try {
            const startOfDay = Timestamp.fromDate(new Date(new Date().setHours(0, 0, 0, 0)));
            const endOfDay = Timestamp.fromDate(new Date(new Date().setHours(23, 59, 59, 999)));

            const q1 = query(collection(db, 'orders'), where('totalamount', '>', 0), where('created', '>=', startOfDay), where('created', '<=', endOfDay),
                orderBy("created", "desc"), limit(200)
            );

            onSnapshot(q1, async (querySnapshot1) => {
                const promises = querySnapshot1.docs.map(async (doc1) => {
                    const orderData = doc1.data();
                    let custname = "", mobile = "", staffname = orderData.staff?.label || "";

                    if (orderData.billtype === 'Room Posting' && orderData.bookingId) {
                        const bookingSnap = await getDoc(doc(db, "bookings", orderData.bookingId));
                        if (bookingSnap.exists()) {
                            const customers = bookingSnap.data().customers;
                            custname = customers?.custname || "";
                            mobile = customers?.mobile || "";
                        }
                    }

                    if (orderData.billtype === 'Credit Account' && orderData.custid) {
                        const customerSnap = await getDoc(doc(db, "restocustomers", orderData.custid));
                        if (customerSnap.exists()) {
                            const customerData = customerSnap.data();
                            custname = customerData?.name || "";
                            mobile = customerData?.mobile || "";
                        }
                    }

                    return {
                        id: doc1.id,
                        data: orderData,
                        custname: custname,
                        staffname: staffname,
                        mobile: mobile,
                    };
                });

                const finalData = await Promise.all(promises);
                setorders(finalData);
            });

            const q66 = query(collection(db, 'restopaymodes'))
            onSnapshot(q66, (querySnapshot) => {
                setpaymodes(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });

            const q8 = query(collection(db, 'bookings'), orderBy("createdAt", "asc"), where('status', '==', 'Booked'))
            onSnapshot(q8, async (querySnapshot1) => {
                const combinedData1 = [];
                for (const doc1 of querySnapshot1.docs) {
                    for (let i = 0; i < doc1.data().rooms.length; i++) {
                        combinedData1.push({
                            id: doc1.id,
                            data: doc1.data(),
                            label: doc1.data().rooms[i].roomname + " / " + doc1.data().customers.custname,
                            value: doc1.id,
                        });
                    }
                }
                setbookings(combinedData1);
            });


        } catch (error) {
            console.log(error.message);
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const fetchData2 = async () => {
        try {
            if (orders) {
                const filteredData = orders.filter((item) =>
                    item.custname.toUpperCase().includes(searchText.toUpperCase()) ||
                    item.data.billno.toString().includes(searchText)
                );
                setorders(filteredData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const handleView = (docid, paytype) => {
        setupdocid(docid);
        setpaytype(paytype);
        // const q = query(collection(db, 'orderitems'), where("orderid", "==", docid));
        // onSnapshot(q, (querySnapshot2) => {
        //     setpurchaseItesms(querySnapshot2.docs.map(doc => ({
        //         id: doc.id,
        //         data: doc.data(),
        //     })));
        // });
    }

    const handleView1 = async (docid, orderdate, billno, totalamount, paid, discount, pax, subtotal, tax, custname, mobile, tableid) => {

        setnofopax(0)
        setorderdate("");
        setbillno("");
        setsubtotal(0);
        setgstamount(0);
        settotalamount(0);
        // settotalamount1(parseFloat(totalamount));
        setcustmobile(mobile)
        setcustname(custname)
        settablename("");

        const q = query(collection(db, 'orderitems'), where("orderid", "==", docid));
        onSnapshot(q, (querySnapshot2) => {
            setorderitems(querySnapshot2.docs.map(doc => ({
                id: doc.id,
                data: doc.data(),
            })));
        });

        if (tableid) {
            const docProdRef1 = doc(db, 'seatings', tableid);
            const docSnap1 = await getDoc(docProdRef1);
            settablename(docSnap1.data().seatname);
        }


        setnofopax(pax)
        setorderdate(orderdate);
        setbillno(billno);
        setsubtotal(parseFloat(subtotal));
        setgstamount(parseFloat(tax) / 2);
        settotalamount(parseFloat(totalamount));
        // settotalamount1(parseFloat(totalamount));
        setcustmobile(mobile)
        setcustname(custname)
    }

    const handleView2 = async (docid) => {
        const q = query(collection(db, 'watchreport'), where("orderid", "==", docid), orderBy('created', 'asc'));
        onSnapshot(q, (querySnapshot2) => {
            setwatchreports(querySnapshot2.docs.map(doc => ({
                id: doc.id,
                data: doc.data(),
            })));
        });
    }

    const updateData = async () => {
        await updateDoc(doc(db, 'orders', updocid), {
            paytype: paytype,
        });
        fetchData1()
        Toast.fire({ icon: "success", title: 'Payment Mode Successfully Updated' });
    }

    const getData = async () => {
        if (fromdate === "" || todate === '') {
            Toast.fire({ icon: "error", title: 'Please select From Date & To Date' });
            return;
        }
        setorders([])
        const q1 = query(collection(db, 'orders'), orderBy("created", "desc"), where('totalamount', '>', 0),
            where('orderdate', '>=', fromdate), where('orderdate', '<=', todate));
        onSnapshot(q1, async (querySnapshot1) => {
            const promises = querySnapshot1.docs.map(async (doc1) => {
                let custname = "";
                let mobile = "";
                let staffname = "";
                const orderData = doc1.data();

                if (orderData.billtype === 'Room Posting') {
                    const bookingPromise = orderData.bookingId
                        ? getDoc(doc(db, "bookings", orderData.bookingId))
                        : Promise.resolve(null);

                    const [bookingSnap] = await Promise.all([bookingPromise]);
                    custname = bookingSnap.data()?.customers.custname || "";
                    mobile = bookingSnap.data()?.customers.mobile || "";
                }

                if (orderData.billtype === 'Credit Account') {
                    const customerPromise = orderData.custid
                        ? getDoc(doc(db, "restocustomers", orderData.custid))
                        : Promise.resolve(null);
                    const [customerSnap] = await Promise.all([customerPromise]);
                    custname = customerSnap?.data()?.name || "";
                    mobile = customerSnap?.data()?.mobile || "";
                }
                staffname = orderData.staff?.label || "";
                return {
                    id: doc1.id,
                    data: orderData,
                    custname: custname,
                    staffname: staffname,
                    mobile: mobile,
                };
            });
            const finalData = await Promise.all(promises);
            setorders(finalData);
        });
    }

    const handleSelectItem3 = async (item) => {
        setbookingData(item);
        setbookingId(item.id);
        setcustmobile(item.data.customers.mobile);
    };

    const updateBooking = async () => {

        const orderRef = doc(db, "orders", updocid);
        const orderSnap = await getDoc(orderRef);

        // ========================= Old Booking Id Update ===================================

        const bookingRef1 = doc(db, "bookings", orderSnap.data().bookingId);
        const bookingSnap1 = await getDoc(bookingRef1);

        if (bookingSnap1.exists()) {
            const bookingData = bookingSnap1.data();
            const totalResto = parseFloat(bookingData.restoamount || 0) - parseFloat(orderSnap.data().totalamount);
            const totalBill = parseFloat(bookingData.totalamount || 0) - parseFloat(orderSnap.data().totalamount);
            const totalPaid = parseFloat(bookingData.advance || 0) - parseFloat(orderSnap.data().paid);
            const totalRemaining = totalBill - totalPaid;

            await updateDoc(doc(db, 'bookings', orderSnap.data().bookingId), {
                restoamount: totalResto,
                totalamount: totalBill,
                advance: totalPaid,
                remaining: totalRemaining,
            });
        }
        // ========================= Old Booking Id Update ===================================


        // ========================= New Booking Id Update ===================================
        await updateDoc(doc(db, 'orders', updocid), {
            bookingId: bookingId || null,
        });

        const bookingRef = doc(db, "bookings", bookingId);
        const bookingSnap = await getDoc(bookingRef);

        if (bookingSnap.exists()) {
            const bookingData = bookingSnap.data();
            const totalResto = parseFloat(orderSnap.data().totalamount) + parseFloat(bookingData.restoamount || 0);
            const totalBill = parseFloat(orderSnap.data().totalamount) + parseFloat(bookingData.totalamount || 0);
            const totalPaid = parseFloat(orderSnap.data().paid) + parseFloat(bookingData.advance || 0);
            const totalRemaining = totalBill - totalPaid;

            await updateDoc(doc(db, 'bookings', bookingId), {
                restoamount: totalResto,
                totalamount: totalBill,
                advance: totalPaid,
                remaining: totalRemaining,
            });
        }

        // ========================= New Booking Id Update ===================================


        Toast.fire({ icon: "success", title: 'Order Successfully Updated' });

    }

    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right" }} >|| View Orders ||</button>
                        <a className="btn btn-info btn-sm" href="table-orders">Add Orders</a><br /><br />
                        <div className="card card-primary card-outline">
                            <div className='row p-2'>
                                <div className='col-sm-3'>
                                    <label>Search Here </label>
                                    <input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                                        className='form-control' placeholder='Search......' />&nbsp;
                                </div>
                                <div className="col-sm-3">
                                    <label>From Date </label>
                                    <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={fromdate} onChange={(e) => setfromdate(e.target.value)} />
                                </div>
                                <div className="col-sm-3">
                                    <label>To Date </label>
                                    <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={todate} onChange={(e) => settodate(e.target.value)} />
                                </div>
                                <div className='col-sm-3'>
                                    <br />
                                    <button onClick={() => getData()} className='btn btn-info'>Get Report</button>
                                </div>
                            </div>

                            <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Order Date</th>
                                            <th>Bill No</th>
                                            <th>Customer Name</th>
                                            <th>Grand Total </th>
                                            {/* <th>Discount </th> */}
                                            <th>Paid Amount</th>
                                            <th>Remaining</th>
                                            {/* <th>Staff</th> */}
                                            <th>Bill Type</th>
                                            <th>Pay Type</th>
                                            {/* <th>Status</th> */}
                                            {/* <th>Note</th> */}
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            orders.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>
                                                        {moment(item.data.orderdate).format('DD-MM-YYYY')}<br/>
                                                        {
                                                            item.data.status === 'Cancled' &&
                                                            <b style={{ color: "red" }}>Canceled</b>
                                                        }
                                                    </td>
                                                    <td>{item.data.billno}</td>
                                                    <td>{item.custname}</td>
                                                    <td>{item.data.totalamount.toFixed(2)}</td>
                                                    {/* <td>{item.data.discount}</td> */}
                                                    <td>{item.data.paid}</td>
                                                    <td>{parseFloat(item.data.totalamount - item.data.paid - item.data.discount).toFixed(2)}</td>
                                                    {/* <td>{item.staffname}</td> */}
                                                    <td>{item.data.billtype}</td>
                                                    <td>{item.data.paytype}</td>
                                                    {/* <td>{item.data.status}</td> */}
                                                    {/* <td>{item.data.note}</td> */}
                                                    <td>
                                                        {/* <a onClick={() => handleView(item.id, item.data.paytype)} className="btn btn-sm btn-success" href='#0' data-toggle="modal" data-target="#text-modal1"> <i className="fa fa-edit"></i></a>&nbsp; */}
                                                        <a onClick={() => handleView(item.id, item.data.paytype)} className="btn btn-sm btn-warning" href='#0' data-toggle="modal" data-target="#text-modal1"> <i className="fa fa-edit"></i></a>&nbsp;
                                                        <a onClick={() => handleView1(item.id, item.data.orderdate, item.data.billno, item.data.totalamount, item.data.paid, item.data.discount, item.data.noofpax, item.data.subtotal, item.data.tax, item.custname, item.mobile, item.data.tableid)} className="btn btn-sm btn-info" href='#0' data-toggle="modal" data-target="#text-modal3"> <i className="fa fa-print"></i></a>&nbsp;
                                                        {
                                                            item.data.billtype === 'Room Posting' &&
                                                            <a onClick={() => setupdocid(item.id)} className="btn btn-sm bg-purple" href='#0' data-toggle="modal" data-target="#text-modal2"> <i className="fa fa-exchange"></i></a>

                                                        }
                                                        {/* <a onClick={() => handleView2(item.id)} className="btn btn-sm btn-default" href='#0' data-toggle="modal" data-target="#text-modal4"> <i className="fa fa-eye"></i></a>&nbsp; */}
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">View Order Items</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-sm-3'>
                                    <label>Payment Type</label>
                                    <select className='form-control' style={{ height: 35, fontSize: 14 }} value={paytype} onChange={(e) => setpaytype(e.target.value)}>
                                        <option></option>
                                        {
                                            paymodes.map((item, index) =>
                                                <option key={index} value={item.data.paymode}>{item.data.paymode}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-info" onClick={updateData} >Update</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="text-modal2">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Change Booking</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-sm-3'>
                                    <label>Select Room </label>
                                    <Select options={bookings} value={bookingData} onChange={handleSelectItem3} />
                                </div>
                                <div className='col-sm-3'>
                                    <label>Mobile No </label>
                                    <input className='form-control' value={custmobile} readOnly />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-success" onClick={() => updateBooking()}>Update</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="text-modal3">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Print Bill</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div style={{ padding: 20 }} ref={printRef}>
                                <div className='col-sm-4'>
                                    <center>
                                        <h2 style={{ textTransform: "uppercase" }}>THE SKYLAND  </h2>
                                        <span style={{ fontSize: 15, color: "black" }}>Near New Bus Stand, Latur Road, Tuljapur, Dist. Dharashiv, 413 601</span><br />
                                        <span style={{ fontSize: 15, color: "black" }}>Mobile No: 7077779163, 7077779164, 7077779165 </span><br />
                                        <span style={{ fontSize: 15, color: "black" }}>GSTIN No: 27AAWFP8677Q1ZN </span><br />
                                    </center>
                                    <br />
                                    <table width="100%">
                                        <tr>
                                            <td style={{ color: "black", fontSize: 15 }}>Bill No : {billno} </td>
                                            <td style={{ textAlign: "right", color: "black", fontSize: 15 }}>Table No : {tablename}</td>
                                        </tr>
                                        <tr >
                                            <td style={{ color: "black", fontSize: 15 }}>Date :  {moment(orderdate).format('DD/MM/YYYY')} </td>
                                            <td style={{ textAlign: "right", color: "black", fontSize: 15 }}>Time :  {moment().format('HH:mm:ss A')} </td>
                                        </tr>
                                        <tr >
                                            <td colSpan={2} style={{ color: "black", fontSize: 15 }}>No of Person :  {nofopax} </td>
                                        </tr>
                                        <tr >
                                            <td style={{ color: "black", fontSize: 15 }}>Guest Name  :  {custname} </td>
                                            <td style={{ textAlign: "right", color: "black", fontSize: 15 }}>Mobile  :  {custmobile} </td>
                                        </tr>
                                    </table>
                                    <hr />
                                    <table width="100%">
                                        <tr>
                                            <th style={{ fontSize: 17, color: "black" }}>Item Name</th>
                                            <th style={{ fontSize: 17, color: "black", textAlign: "right" }}>Qty</th>
                                            <th style={{ fontSize: 17, color: "black", textAlign: "right" }}>Rate</th>
                                            <th style={{ fontSize: 17, color: "black", textAlign: "right" }}>Total</th>
                                        </tr>
                                        {
                                            orderitems.map((item, index) =>
                                                <tr key={index}>
                                                    <td style={{ fontSize: 17, color: "black" }}>{item.data.itemname}  </td>
                                                    <td style={{ fontSize: 17, color: "black", textAlign: "right" }}>{item.data.quantity}</td>
                                                    <td style={{ fontSize: 17, color: "black", textAlign: "right" }}>{item.data.rate}</td>
                                                    <td style={{ fontSize: 17, color: "black", textAlign: "right" }}>{parseFloat(item.data.total).toFixed(2)}/-</td>
                                                </tr>
                                            )
                                        }
                                    </table>
                                    <hr />
                                    <h6 style={{ marginBottom: 10 }}>Sub Total :  <span style={{ float: "right" }}> {subtotal.toFixed(2)}</span> </h6>
                                    <h6 style={{ marginBottom: 10 }}>CGST (2.5%) :  <span style={{ float: "right" }}> {gstamount.toFixed(2)}</span> </h6>
                                    <h6 style={{ marginBottom: 10 }}>SGST (2.5%) :  <span style={{ float: "right" }}> {gstamount.toFixed(2)}</span> </h6>
                                    <h4 style={{ marginBottom: 10 }}>Total Amount :  <span style={{ float: "right" }}> {totalamount.toFixed(2)}</span> </h4>
                                    <hr />
                                    <center>
                                        <h4>Thank You</h4>
                                    </center>
                                    <span style={{ fontSize: 15, color: "black" }}>Printed By  : {username}</span><br />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-success" onClick={() => handlePrint1()}>Print</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="text-modal4">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Watch Report</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <table className="table table-bordered">
                                <tr>
                                    <th >Sr No</th>
                                    <th >Descreption</th>
                                    {/* <th >Datetime</th> */}
                                </tr>
                                {
                                    watchreports.map((item, index) =>
                                        <tr key={index}>
                                            <td >{index + 1}  </td>
                                            <td >{item.data.descreption}  </td>
                                        </tr>
                                    )
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
