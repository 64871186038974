import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, limit, getCountFromServer, updateDoc, writeBatch, getAggregateFromServer, sum } from 'firebase/firestore'
import Select from 'react-select'
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { ClipLoader } from 'react-spinners';

function Tableorders() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  const printRef = useRef();
  const printRef1 = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  const handleKotPrint = useReactToPrint({
    content: () => printRef1.current,
  })

  const [loadincount, setloadincount] = useState(true);
  const [username, setusername] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [billtype, setbilltype] = useState("Regular Bill");
  const [note, setnote] = useState("");

  const [bookings, setbookings] = useState([]);
  const [bookingData, setbookingData] = useState("");
  const [bookingId, setbookingId] = useState("");

  const [userData, setUserData] = useState();
  const [seatingCat, setseatingCat] = useState([]);
  const [seatings, setseatings] = useState([]);
  const [productCat, setproductCat] = useState([]);
  const [products, setproducts] = useState([]);
  const [orderitems, setorderitems] = useState([]);
  const [printorderitems, setprintorderitems] = useState([]);
  const [customers, setcustomers] = useState([]);
  const [staffs, setstaffs] = useState([]);
  const [paymodes, setpaymodes] = useState([]);

  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");

  const [orderDocid, setorderDocid] = useState("");
  const [nofopax, setnofopax] = useState("");
  const [tableid, settableid] = useState("");
  const [tablename, settablename] = useState("");
  const [orderdate, setorderdate] = useState("");
  const [custid, setcustid] = useState("");
  const [customer, setcustomer] = useState("");
  const [staffid, setstaffid] = useState("");
  const [staff, setstaff] = useState("");
  const [billno, setbillno] = useState("");
  const [tablebillno, settablebillno] = useState("");
  const [subtotal, setsubtotal] = useState(0);
  const [tax, settax] = useState(0);
  const [servicecharge, setservicecharge] = useState(0);
  const [tip, settip] = useState(0);
  const [totalamount, settotalamount] = useState(0);
  const [roundoff, setroundoff] = useState(0);
  const [discount, setdiscount] = useState(0);
  const [paid, setpaid] = useState(0);
  const [remaining, setremaining] = useState(0);
  const [paytype, setpaytype] = useState("");
  const [item, setitem] = useState("");

  const [revrem, setrevrem] = useState(0);

  const [removeNote, setremoveNote] = useState("");
  const [deleteItems, setdeleteItems] = useState([]);
  const [updateQtys, setupdateQtys] = useState([]);
  const [itemReady, setitemReady] = useState("Yes");
  const [newQty, setnewQty] = useState(0);

  const [openfoodname, setopenfoodname] = useState("");
  const [openfoodqty, setopenfoodqty] = useState(0);
  const [openfoodrate, setopenfoodrate] = useState(0);
  const [openfoodtotal, setopenfoodtotal] = useState(0);

  const [itemdesc, setitemdesc] = useState("");
  const [cancleby, setcancleby] = useState("Cancle By Customer");
  const [itemid, setitemid] = useState("");

  const [custmobile, setcustmobile] = useState("");

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUserData(user);
      fetchUsersData()
    });
  });

  const fetchUsersData = async () => {
    try {
      if (userData) {
        if (loadincount) {
          const docRef = doc(db, "users", userData.uid)
          const docSnap = await getDoc(docRef)
          if (docSnap.exists()) {
            setusername(docSnap.data().username);
            setloadincount(false)
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    finaltotal(0)
    fetchData1();
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setorderdate(formattedDate);
  }, []);


  const fetchData1 = async () => {
    try {
      const q = query(collection(db, 'seatingcats'), orderBy('srno', 'asc'))
      onSnapshot(q, (querySnapshot) => {
        setseatingCat(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });

      const q66 = query(collection(db, 'restopaymodes'))
      onSnapshot(q66, (querySnapshot) => {
        setpaymodes(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });

      const fetchSeatings = async () => {
        const q1 = query(collection(db, 'seatings'), orderBy("created", "asc"));
        const querySnapshot1 = await getDocs(q1);
        const seatings = querySnapshot1.docs.map(doc => ({ id: doc.id, data: doc.data() }));
        return seatings;
      };

      const fetchOrders = async () => {
        const q2 = query(collection(db, 'orders'), where("status", "==", 'Booked'), orderBy("created", "asc"));
        const querySnapshot2 = await getDocs(q2);
        const orders = querySnapshot2.docs.map(doc => ({ id: doc.id, data: doc.data() }));
        return orders;
      };

      const combineSeatingsAndOrders = (seatings, orders) => {
        const combinedData = seatings.map(seating => {
          const seatingOrders = orders.filter(order => order.data.tableid === seating.id);

          return {
            id: seating.id,
            data: seating.data,
            status: seatingOrders.length > 0 ? "Booked" : "Available",
            orders: seatingOrders
          };
        });
        return combinedData;
      };

      const updateSeatings = async () => {
        try {
          const [seatings, orders] = await Promise.all([fetchSeatings(), fetchOrders()]);
          const combinedData = combineSeatingsAndOrders(seatings, orders);
          setseatings(combinedData);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };

      updateSeatings();

      const q2 = query(collection(db, 'productcats'))
      onSnapshot(q2, (querySnapshot) => {
        setproductCat(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });

      const q3 = query(collection(db, 'products'), orderBy("prodname", "asc"))
      onSnapshot(q3, async (querySnapshot1) => {
        const combinedData1 = [];
        let rohan = 0;
        for (const doc1 of querySnapshot1.docs) {
          rohan++;
          combinedData1.push({
            id: doc1.id,
            data: doc1.data(),
            unit: doc1.data().unitname,
            label: rohan + ". " + doc1.data().prodname,
            value: doc1.id,
          });
        }
        setproducts(combinedData1);
      });

      const q6 = query(collection(db, 'restocustomers'))
      onSnapshot(q6, (querySnapshot) => {
        setcustomers(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data(),
          label: doc.data().name,
          value: doc.id,
        })));
      });

      const q7 = query(collection(db, 'staffs'))
      onSnapshot(q7, (querySnapshot) => {
        setstaffs(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data(),
          label: doc.data().name,
          value: doc.id,
        })));
      });

      const q8 = query(collection(db, 'bookings'), orderBy("createdAt", "asc"), where('status', '==', 'Booked'))
      onSnapshot(q8, async (querySnapshot1) => {
        const combinedData1 = [];
        for (const doc1 of querySnapshot1.docs) {
          for (let i = 0; i < doc1.data().rooms.length; i++) {
            combinedData1.push({
              id: doc1.id,
              data: doc1.data(),
              label: doc1.data().rooms[i].roomname + " / " + doc1.data().customers.custname,
              value: doc1.id,
            });
          }
        }
        setbookings(combinedData1);
      });


      const q111 = query(collection(db, 'plans'))
      onSnapshot(q111, (querySnapshot) => {
        if (querySnapshot.size > 0) {
          let today = moment().format('YYYY-MM-DD');
          let enddate = querySnapshot.docs[0].data().enddate;
          if (today > enddate) {
            window.location.href = "/plan-expired"
          }

        }
      });

    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message
      });
    }
  }

  const fetchProducts = (prodcatid) => {
    const q3 = query(collection(db, 'products'), where("prodcatid", "==", prodcatid))
    onSnapshot(q3, async (querySnapshot1) => {
      const combinedData = [];
      for (const doc1 of querySnapshot1.docs) {
        combinedData.push({
          id: doc1.id,
          data: doc1.data(),
          unit: doc1.data().unitname,
          label: doc1.data().prodname + " " + doc1.data().unitname,
          value: doc1.id,
        });
      }
      setproducts(combinedData);
    });
  }

  const handleSelectItem = (item) => {
    setitem("");
    addOrderItems(item, item.id, item.data.rate)
  };

  const handleSelectItem1 = async (item) => {
    setcustomer(item);
    setcustid(item.id);
    setbookingId(item.data.bookingId);
    if (orderDocid) {
      await updateDoc(doc(db, 'orders', orderDocid), {
        custid: item.id || null,
        customer: item || null,
      });
    }

    // const q1 = query(collection(db, 'customeracchistory'), where("custid", "==", item.id));
    // const snapshot = await getAggregateFromServer(q1, {
    //   paid: sum('paid')
    // });

    // const q2 = query(collection(db, 'orders'), where("custid", "==", item.id));
    // const snapshot1 = await getAggregateFromServer(q2, {
    //   subtotal: sum('subtotal')
    // });

    // const g3 = query(collection(db, 'customeracchistory'), where("custid", "==", item.id));
    // const snapshot2 = await getAggregateFromServer(g3, {
    //   discount: sum('discount')
    // });

    // let remaining = parseFloat(snapshot1.data().subtotal) - parseFloat(snapshot2.data().discount) - parseFloat(snapshot.data().paid);
    // setrevrem(remaining);

  };

  const handleSelectItem2 = async (item) => {
    setstaff(item);
    setstaffid(item.id);
  }

  const addTableId = async (tableid, tablename) => {
    setrevrem(0);
    setsubtotal(0);
    setservicecharge(0)
    settip(0)
    settax(0);
    setnofopax(0);
    settotalamount(0);
    settableid(tableid); settablename(tablename);
    setorderDocid("");
    setorderitems([]);
    const coll = collection(db, "orders");
    const snapshot = await getCountFromServer(coll, where("orderdate", "==", orderdate));
    let billno = parseFloat(snapshot.data().count) + 1;
    setbillno(billno);

    setcustid(snapshot.data().custid);
    setcustomer("");
    setstaff("");
    setstaffid(snapshot.data().staffid);

    const coll1 = collection(db, "orders");
    const snapshot1 = await getCountFromServer(coll1, where("tableid", "==", tableid), where("orderdate", "==", orderdate));
    let tablebillno = parseFloat(snapshot1.data().count) + 1;
    settablebillno(tablebillno);
  }

  const addTableId1 = async (tableid, tablename, tableno, billno1, orderid, custid, customer, staffid, staff, nofopax) => {
    fetchOrderItems(tableid, tableno, orderid);
    settablebillno(tableno);
    settableid(tableid);
    settablename(tablename);
    setorderDocid(orderid);
    setbillno(billno1);
    setrevrem(0);
    setsubtotal(0);
    settax(0);
    setservicecharge(0)
    settip(0)
    settotalamount(0);
    setnofopax(nofopax)

    if (custid) {
      setcustid(custid);
      setcustomer(customer);
      setbookingId(customer.data.bookingId);

      setstaff(staff);
      setstaffid(staffid);

      const q1 = query(collection(db, 'customeracchistory'), where("custid", "==", custid));
      const snapshot = await getAggregateFromServer(q1, {
        paid: sum('paid')
      });

      const q2 = query(collection(db, 'orders'), where("custid", "==", custid));
      const snapshot1 = await getAggregateFromServer(q2, {
        subtotal: sum('subtotal')
      });

      const g3 = query(collection(db, 'customeracchistory'), where("custid", "==", custid));
      const snapshot2 = await getAggregateFromServer(g3, {
        discount: sum('discount')
      });

      let remaining = parseFloat(snapshot1.data().subtotal) - parseFloat(snapshot2.data().discount) - parseFloat(snapshot.data().paid);
      setrevrem(remaining);

      await addDoc(collection(db, 'watchreport'), {
        orderid: orderid || null,
        descreption: tablename + 'Table Opened',
        uid: userData.uid,
        created: Timestamp.now(),
      });


    }

  }

  // Helper to update product stock
  // const updateProductStock = async (itemid) => {
  //   const docProdRef = doc(db, 'products', itemid);
  //   const docSnap = await getDoc(docProdRef);
  //   if (docSnap.exists()) {
  //     let newstock = parseFloat(docSnap.data().stock) - 1;
  //     await updateDoc(doc(db, 'products', itemid), { stock: newstock });
  //   }
  // };

  // Helper to add or update order items

  // Main addOrderItems function
  const addOrderItems = async (item, itemid, rate) => {
    try {
      let orderRef;

      // Batch to group writes
      const batch = writeBatch(db);

      // Check if there's an existing order, otherwise create a new one
      if (!orderDocid) {
        orderRef = doc(collection(db, 'orders')); // Create a new order ref

        batch.set(orderRef, {
          bookingId: bookingId || null,
          tableid: tableid || null,
          nofopax: nofopax || null,
          custid: custid || null,
          staffid: staffid || null,
          customer: customer || null,
          staff: staff || null,
          orderdate: orderdate || null,
          tablebillno: tablebillno || null,
          billno: billno || null,
          subtotal: parseFloat(subtotal) || 0,
          tax: parseFloat(tax) || 0,
          servicecharge: parseFloat(servicecharge) || 0,
          tip: parseFloat(tip) || 0,
          totalamount: parseFloat(totalamount) || 0,
          discount: parseFloat(discount) || 0,
          paid: parseFloat(paid) || 0,
          remaining: parseFloat(remaining) || 0,
          paytype: paytype || null,
          billtype: billtype || null,
          note: note || null,
          status: 'Booked',
          uid: userData.uid,
          created: Timestamp.now()
        });

        await addDoc(collection(db, 'watchreport'), {
          orderid: orderRef.id || null,
          descreption: 'New Order Added',
          uid: userData.uid,
          created: Timestamp.now(),
        });

        setorderDocid(orderRef.id); // Update state with new order ID
      } else {
        orderRef = doc(db, 'orders', orderDocid);
        batch.update(orderRef, {
          tableid: tableid || null,
          nofopax: nofopax || null,
          bookingId: bookingId || null,
          custid: custid || null,
          staffid: staffid || null,
          customer: customer || null,
          staff: staff || null,
          orderdate: orderdate || null,
          tablebillno: tablebillno || null,
          billno: billno || null,
          subtotal: parseFloat(subtotal) || 0,
          tax: parseFloat(tax) || 0,
          servicecharge: parseFloat(servicecharge) || 0,
          tip: parseFloat(tip) || 0,
          totalamount: parseFloat(totalamount) || 0,
          discount: parseFloat(discount) || 0,
          paid: parseFloat(paid) || 0,
          remaining: parseFloat(remaining) || 0,
          paytype: paytype || null,
          billtype: billtype || null,
          note: note || null,
          status: 'Booked',
          uid: userData.uid,
          updated: Timestamp.now()
        });

        await addDoc(collection(db, 'watchreport'), {
          orderid: orderDocid || null,
          descreption: ' Order Data Updated',
          uid: userData.uid,
          created: Timestamp.now(),
        });
      }

      // Upsert order item in the batch (for better performance)
      await upsertOrderItemBatch(batch, orderRef.id, item, itemid, rate);

      // Commit batch (write everything atomically)
      await batch.commit();

      // Fetch updated order items and other data
      fetchOrderItems(tableid, tablebillno, orderRef.id);
      fetchData1();

    } catch (error) {
      console.log('Error adding order items:', error.message);
    }
  };

  const upsertOrderItemBatch = async (batch, orderDocid, item, itemid, rate) => {
    try {
      // Query to check if the order item already exists
      const itemQuery = query(
        collection(db, "orderitems"),
        where("itemid", "==", itemid),
        where("orderid", "==", orderDocid)
      );
      const itemSnapshot = await getDocs(itemQuery);

      let taxamount = parseFloat(rate) * 0.05;
      let taxadded = parseFloat(taxamount) + parseFloat(rate);

      if (itemSnapshot.docs.length === 0) {
        // Add new order item using batch
        const newItemRef = doc(collection(db, 'orderitems'));
        batch.set(newItemRef, {
          orderid: orderDocid,
          itemname: item.data.prodname || null,
          kot: item.data.kot || null,
          status: 'Pending',
          complete: 0,
          itemid: itemid || null,
          unitname: item.unit || null,
          quantity: 1,
          rate: parseFloat(rate),
          total: parseFloat(rate),
          taxamount: parseFloat(taxamount),
          taxaddedamount: parseFloat(taxadded),
          uid: userData.uid,
          created: Timestamp.now(),
          tableid: tableid || null,
          nofopax: nofopax || null,
          bookingId: bookingId || null,
          custid: custid || null,
          staffid: staffid || null,
          customer: customer || null,
          staff: staff || null,
          orderdate: orderdate || null,
          itemdesc: itemdesc,
          tablebillno: tablebillno || null,
          billno: billno || null,
          cancleby: null,
        });

        let descreption = ' Order Items Added Item ' + item.data.prodname + ', Quantity ' + 1;
        await addDoc(collection(db, 'watchreport'), {
          orderid: orderDocid || null,
          descreption: descreption,
          uid: userData.uid,
          created: Timestamp.now(),
        });

      } else {
        // Update existing order item using batch
        let docRef = itemSnapshot.docs[0].ref;
        let currentData = itemSnapshot.docs[0].data();
        let newQty = (currentData.quantity || 0) + 1;
        let newTotal = newQty * rate;
        let newTaxamount = newTotal * 0.05;
        let newTaxadded = newTotal + newTaxamount;

        batch.update(docRef, {
          quantity: newQty,
          total: parseFloat(newTotal),
          taxamount: parseFloat(newTaxamount),
          taxaddedamount: parseFloat(newTaxadded),
          updated: Timestamp.now(),
        });

        let descreption = ' Order Items Updated Item ' + currentData.itemname + ', Quantity ' + newQty;
        await addDoc(collection(db, 'watchreport'), {
          orderid: orderDocid || null,
          descreption: descreption,
          uid: userData.uid,
          created: Timestamp.now(),
        });
      }
    } catch (error) {
      console.log('Error in upsertOrderItemBatch:', error.message);
    }
  };

  const fetchOrderItems = async (tableid, tablebillno, orderid) => {
    try {
      // Combined query for fetching all order items (fetch only necessary fields)
      const q = query(
        collection(db, "orderitems"),
        where("orderid", "==", orderid),
        orderBy('created', 'asc')
        // .select("total", "taxamount", "quantity", "complete", "kot") // Uncomment if Firestore supports field selection
      );

      // Fetch documents only once
      const querySnapshot = await getDocs(q);

      // Initialize variables for batch processing
      const orderItems = [];
      const printItems = [];
      let sum = 0;
      let tax = 0;

      // Process data in a single loop
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const total = data.total ? data.total : 0;
        const taxAmount = data.taxamount ? data.taxamount : 0;

        sum += total;
        tax += taxAmount;

        // Push each item to the orderItems array
        orderItems.push({ id: doc.id, data });

        // Add to printItems if criteria are met
        const qty = parseFloat(data.quantity) - parseFloat(data.complete);

        if (data.kot === 'YES' && parseFloat(qty) > 1) {
          printItems.push({
            id: doc.id,
            data,
            qty,
          });
        }
      });

      // Update orderItems and printItems state only once
      setorderitems(orderItems);
      setprintorderitems(printItems);

      // Calculate totals and tax only once
      settax(parseFloat(tax).toFixed(0));
      setsubtotal(parseFloat(sum).toFixed(0));

      // Compute total amount and roundoff
      const totalAmount = parseFloat(sum) + parseFloat(tax) + parseFloat(servicecharge) + parseFloat(tip);
      const roundoff = parseFloat(totalAmount.toFixed() - totalAmount.toFixed(2));
      setroundoff(parseFloat(roundoff).toFixed(2));
      settotalamount(parseFloat(totalAmount + roundoff).toFixed(2));

      // let descreption = 'Order item fetched ';
      // await addDoc(collection(db, 'watchreport'), {
      //   orderid: orderid || null,
      //   descreption: descreption,
      //   uid: userData.uid,
      //   created: Timestamp.now(),
      // });
      // Call finaltotal after all calculations
      finaltotal(0);
    } catch (error) {
      console.log('Error fetching order items:', error.message);
    }
  };


  const deleteOrderItem = async (docid, itemid, quantity, tableid, tablebillno, orderid, rate, status) => {
    //  // =============== Product Stock Update ================
    //  const docProdRef = doc(db, 'products', itemid);
    //  const docSnap = await getDoc(docProdRef);
    //  if (docSnap.exists()) {
    //    let newstock = parseFloat(docSnap.data().stock) + parseFloat(quantity);
    //    await updateDoc(doc(db, 'products', itemid), {
    //      stock: newstock,
    //    });
    //  }
    setremoveNote("")
    if (status === 'Active' || status === 'Bill') {
      setdeleteItems([docid, itemid, quantity, tableid, tablebillno, orderid])
    } else {

      const docRef = doc(db, 'orderitems', docid);
      const docSnap = await getDoc(docRef);

      const taskDocRef = doc(db, 'orderitems', docid)
      await deleteDoc(taskDocRef);
      const q1 = query(collection(db, 'orderitems'), where("orderid", "==", orderid));
      onSnapshot(q1, async (querySnapshot) => {
        if (querySnapshot.size === 0) {
          const taskDoc2Ref = doc(db, 'orders', orderid)
          await deleteDoc(taskDoc2Ref);
          setorderDocid("")
        }
      });
      fetchData1()
      fetchOrderItems(tableid, tablebillno, orderid);

      let descreption = 'Delete Order Items ' + docSnap.data().itemname + ', Quantity ' + docSnap.data().quantity;
      await addDoc(collection(db, 'watchreport'), {
        orderid: orderid || null,
        descreption: descreption,
        uid: userData.uid,
        created: Timestamp.now(),
      });
    }


  }

  const upOrderQty = async (type, docid, itemid, qty, tableid, tablebillno, orderid, rate) => {
    if (type === "Remove") {

      // const docProdRef = doc(db, 'products', itemid);
      // const docSnap = await getDoc(docProdRef);
      // if (docSnap.exists()) {
      //   let newstock = parseFloat(docSnap.data().stock) + parseFloat(qty);
      //   await updateDoc(doc(db, 'products', itemid), {
      //     stock: newstock,
      //   });
      // }


      const docProdRef1 = doc(db, 'orderitems', docid);
      const docSnap1 = await getDoc(docProdRef1);
      if (docSnap1.exists()) {
        let newstock = parseFloat(docSnap1.data().quantity) - parseFloat(qty);
        if (newstock < 1) {

          const docRef = doc(db, 'orderitems', docid);
          const docSnap = await getDoc(docRef);

          const taskDocRef = doc(db, 'orderitems', docid)
          await deleteDoc(taskDocRef);
          const taskDoc2Ref = doc(db, 'orders', orderid)
          await deleteDoc(taskDoc2Ref);
          setorderDocid("");

          let descreption = ' Order Items Removed' + docSnap.data().itemname + ', Quantity ' + newstock;
          await addDoc(collection(db, 'watchreport'), {
            orderid: orderid || null,
            descreption: descreption,
            uid: userData.uid,
            created: Timestamp.now(),
          });

        } else {

          let tot = parseFloat(newstock) * parseFloat(docSnap1.data().rate);
          let taxamount = parseFloat(tot) * 5 / 100;
          let taxadded = parseFloat(taxamount) + parseFloat(tot)

          await updateDoc(doc(db, 'orderitems', docid), {
            quantity: newstock,
            total: parseFloat(tot),
            taxamount: parseFloat(taxamount) || null,
            taxaddedamount: parseFloat(taxadded) || null,
          });

          const docRef = doc(db, 'orderitems', docid);
          const docSnap = await getDoc(docRef);

          let descreption = ' Order Items Updated' + docSnap.data().itemname + ', Quantity ' + newstock;
          await addDoc(collection(db, 'watchreport'), {
            orderid: orderid || null,
            descreption: descreption,
            uid: userData.uid,
            created: Timestamp.now(),
          });
        }
      }

      fetchData1()
      fetchOrderItems(tableid, tablebillno, orderid);
    }
    else {

      // const docProdRef = doc(db, 'products', itemid);
      // const docSnap = await getDoc(docProdRef);
      // if (docSnap.exists()) {
      //   let newstock = parseFloat(docSnap.data().stock) - parseFloat(qty);
      //   await updateDoc(doc(db, 'products', itemid), {
      //     stock: newstock,
      //   });
      // }

      const docProdRef1 = doc(db, 'orderitems', docid);
      const docSnap1 = await getDoc(docProdRef1);
      if (docSnap1.exists()) {
        let newstock = parseFloat(docSnap1.data().quantity) + parseFloat(qty);

        let tot = parseFloat(newstock) * parseFloat(docSnap1.data().rate);
        let taxamount = parseFloat(tot) * 5 / 100;
        let taxadded = parseFloat(taxamount) + parseFloat(tot)

        await updateDoc(doc(db, 'orderitems', docid), {
          quantity: newstock,
          total: parseFloat(tot),
          taxamount: parseFloat(taxamount) || null,
          taxaddedamount: parseFloat(taxadded) || null,
        });

        let descreption = ' Order Items Updated' + docSnap1.data().itemname + ', Quantity ' + newstock;
        await addDoc(collection(db, 'watchreport'), {
          orderid: orderid || null,
          descreption: descreption,
          uid: userData.uid,
          created: Timestamp.now(),
        });


      } else {

        const docProdRef1 = doc(db, 'orderitems', docid);
        const docSnap1 = await getDoc(docProdRef1);

        let descreption = ' Order Items Deleted' + docSnap1.data().itemname + ', Quantity ' + docSnap1.data().quantity;
        await addDoc(collection(db, 'watchreport'), {
          orderid: orderid || null,
          descreption: descreption,
          uid: userData.uid,
          created: Timestamp.now(),
        });

        const taskDocRef = doc(db, 'orderitems', docid)
        await deleteDoc(taskDocRef);
      }


      fetchData1()
      fetchOrderItems(tableid, tablebillno, orderid);
    }
  }




  const finaltotal = (value) => {
    // let totall =  parseFloat(subtotal) + parseFloat(tax) + parseFloat(servicecharge)
    // settotalamount(totall.toFixed(2));
    // console.log(4512451248512);

    if (parseFloat(value) === 1) {
      setbilltype("Room Posting");
    } else {
      setbilltype("Regular Bill");
    }

    let remainingnew = (parseFloat(totalamount)) - (parseFloat(paid) + parseFloat(discount));
    if (remainingnew < 0) {
      // Toast.fire({ icon: "error", title: 'Remaining should not be less than 0' });
      setpaid(0);
      setremaining(totalamount).toFixed(2);
      return;
    }
    if (paid === '') {
      setremaining(totalamount).toFixed(2);
    } else {
      setremaining(remainingnew.toFixed(2));
    }

  }

  const handleSubmit = async () => {
    await addDoc(collection(db, 'restocustomers'), {
      name: name,
      mobile: mobile,
      address: address,
      uid: userData.uid,
      total: 0,
      paid: 0,
      email: "",
      remaining: 0,
      created: Timestamp.now()
    });
    setname(""); setmobile(""); setaddress("");
    fetchData1()
    Toast.fire({
      icon: "success",
      title: 'Credit Account Added Successfully!!'
    });

    let descreption = 'New Credit Account Created ' + name + ', Mobile ' + mobile;
    await addDoc(collection(db, 'watchreport'), {
      orderid: orderDocid || null,
      descreption: descreption,
      uid: userData.uid,
      created: Timestamp.now(),
    });
  }

  const saveBill = async () => {
    try {
      if (!totalamount || !remaining) {
        Toast.fire({ icon: "error", title: 'Please check the total amount, paid & remaining values' });
        return;
      }

      if (billtype === 'Credit Account' && !custid) {
        Toast.fire({ icon: "error", title: 'Please select a Credit Account' });
        return;
      }

      if (billtype === 'Room Posting' && !bookingId) {
        Toast.fire({ icon: "error", title: 'Please select a Room No' });
        return;
      }
      setIsLoading(true)
      let statussss;
      if (billtype === 'Room Posting') {
        statussss = "Bill Pending";
      } else {
        statussss = "Active";
      }

      await updateDoc(doc(db, 'orders', orderDocid),
        {
          status: statussss,
          bookingId: bookingId || null,
          custid: custid || null,
          staffid: staffid || null,
          customer: customer || null,
          staff: staff || null,
          billno: billno || null,
          paiddate: orderdate || null,
          subtotal: parseFloat(subtotal) || 0,
          tax: parseFloat(tax) || 0,
          servicecharge: parseFloat(servicecharge) || 0,
          tip: parseFloat(tip) || 0,
          totalamount: parseFloat(totalamount) || 0,
          discount: parseFloat(discount) || 0,
          paid: parseFloat(paid) || 0,
          remaining: parseFloat(remaining) || 0,
          paytype: paytype || null,
          billtype: billtype || null,
          note: note || null,
        });

      // Handle Credit Account logic
      if (billtype === 'Credit Account') {
        await addDoc(collection(db, 'customeracchistory'), {
          custid: custid || null,
          staffid: staffid || null,
          customer: customer || null,
          staff: staff || null,
          orderDocid: orderDocid || null,
          billno: billno || null,
          paiddate: orderdate || null,
          subtotal: subtotal || null,
          discount: parseFloat(discount) || null,
          paid: parseFloat(paid),
          remaining: remaining,
          paytype: paytype || null,
          status: 'Active',
          note: 'Paid at the time of bill',
          uid: userData.uid,
          created: Timestamp.now(),
        });



        // Fetch and update customer payment data
        const q1 = query(collection(db, 'customeracchistory'), where("custid", "==", custid));
        const q2 = query(collection(db, 'orders'), where("custid", "==", custid));
        const [paidSnapshot, totalSnapshot, discountSnapshot] = await Promise.all([
          getAggregateFromServer(q1, { paidamount: sum('paid') }),
          getAggregateFromServer(q2, { grandtotal: sum('totalamount') }),
          getAggregateFromServer(q2, { discount: sum('discount') })
        ]);

        const totalPaid = paidSnapshot.data().paidamount || 0;
        const totalOrder = totalSnapshot.data().grandtotal || 0;
        const totalDiscount = discountSnapshot.data().discount || 0;
        await updateDoc(doc(db, 'restocustomers', custid),
          {
            total: totalOrder,
            paid: totalPaid,
            remaining: totalOrder - totalPaid - totalDiscount,
          });

      }

      // Handle Room Posting logic
      if (billtype === 'Room Posting') {
        const bookingRef = doc(db, "bookings", bookingId);
        const bookingSnap = await getDoc(bookingRef);

        if (bookingSnap.exists()) {
          const bookingData = bookingSnap.data();
          const totalResto = parseFloat(totalamount) + parseFloat(bookingData.restoamount || 0);
          const totalBill = parseFloat(totalamount) + parseFloat(bookingData.totalamount || 0);
          const totalPaid = parseFloat(paid) + parseFloat(bookingData.advance || 0);
          const totalRemaining = totalBill - totalPaid;

          await updateDoc(doc(db, 'bookings', bookingId), {
            restoamount: totalResto,
            totalamount: totalBill,
            advance: totalPaid,
            remaining: totalRemaining,
          });
        }
      }

      // window.open('https://www.example.com', '_blank', 'width=800,height=600');
      window.open('/print-finalbill/' + orderDocid, '_blank', 'width=800,height=600');

      let descreption = 'Final Bill clicked and Printed Bill,  Service Charge: ' + servicecharge + ', Tip: ' + tip +
        ', Total Amount: ' + parseFloat(totalamount) || 0 + ', Discount: ' + parseFloat(discount) || 0 + ', Paid: ' + parseFloat(paid) || 0 +
        ', Remaining: ' + parseFloat(remaining) || 0 + ', Pay Type: ' + paytype || null + ', Bill Type: ' + billtype || null;

      await addDoc(collection(db, 'watchreport'), {
        orderid: orderDocid || null,
        descreption: descreption,
        uid: userData.uid,
        created: Timestamp.now(),
      });


      // Reset fields and refresh data
      setsubtotal(0); setpaid(0); setremaining(0); setorderDocid("");
      setcustid(""); setbillno(""); setpaytype("Cash"); setorderitems([]);
      setbookingId(""); settotalamount(0);
      fetchData1();
      setIsLoading(false)
      Toast.fire({ icon: "success", title: 'Order Successfully Paid' });
      window.location.href = "/table-orders";

    } catch (error) {
      setIsLoading(false)
      Toast.fire({ icon: "error", title: 'Something went wrong. Please try again.' });
      console.log(error.message);
    }
  };

  const saveBill1 = async () => {
    try {
      if (!totalamount || !remaining) {
        Toast.fire({ icon: "error", title: 'Please check the total amount, paid & remaining values' });
        return;
      }

      if (billtype === 'Credit Account' && !custid) {
        Toast.fire({ icon: "error", title: 'Please select a Credit Account' });
        return;
      }

      if (billtype === 'Room Posting' && !bookingId) {
        Toast.fire({ icon: "error", title: 'Please select a Room No' });
        return;
      }
      // setIsLoading(true)
      let statussss;
      if (billtype === 'Room Posting') {
        statussss = "Bill Pending";
      } else {
        statussss = "Active";
      }

      await updateDoc(doc(db, 'orders', orderDocid),
        {
          // status: statussss,
          bookingId: bookingId || null,
          custid: custid || null,
          staffid: staffid || null,
          customer: customer || null,
          staff: staff || null,
          billno: billno || null,
          paiddate: orderdate || null,
          subtotal: parseFloat(subtotal) || 0,
          tax: parseFloat(tax) || 0,
          servicecharge: parseFloat(servicecharge) || 0,
          tip: parseFloat(tip) || 0,
          totalamount: parseFloat(totalamount) || 0,
          discount: parseFloat(discount) || 0,
          paid: parseFloat(paid) || 0,
          remaining: parseFloat(remaining) || 0,
          paytype: paytype || null,
          billtype: billtype || null,
          note: note || null,
        });

      // Handle Credit Account logic
      // if (billtype === 'Credit Account') {
      //   await addDoc(collection(db, 'customeracchistory'), {
      //     custid: custid || null,
      //     staffid: staffid || null,
      //     customer: customer || null,
      //     staff: staff || null,
      //     orderDocid: orderDocid || null,
      //     billno: billno || null,
      //     paiddate: orderdate || null,
      //     subtotal: subtotal || null,
      //     discount: parseFloat(discount) || null,
      //     paid: parseFloat(paid),
      //     remaining: remaining,
      //     paytype: paytype || null,
      //     status: 'Active',
      //     note: 'Paid at the time of bill',
      //     uid: userData.uid,
      //     created: Timestamp.now(),
      //   });



      // Fetch and update customer payment data
      //   const q1 = query(collection(db, 'customeracchistory'), where("custid", "==", custid));
      //   const q2 = query(collection(db, 'orders'), where("custid", "==", custid));
      //   const [paidSnapshot, totalSnapshot, discountSnapshot] = await Promise.all([
      //     getAggregateFromServer(q1, { paidamount: sum('paid') }),
      //     getAggregateFromServer(q2, { grandtotal: sum('totalamount') }),
      //     getAggregateFromServer(q2, { discount: sum('discount') })
      //   ]);

      //   const totalPaid = paidSnapshot.data().paidamount || 0;
      //   const totalOrder = totalSnapshot.data().grandtotal || 0;
      //   const totalDiscount = discountSnapshot.data().discount || 0;
      //   await updateDoc(doc(db, 'restocustomers', custid),
      //     {
      //       total: totalOrder,
      //       paid: totalPaid,
      //       remaining: totalOrder - totalPaid - totalDiscount,
      //     });

      // }

      // Handle Room Posting logic
      // if (billtype === 'Room Posting') {
      //   const bookingRef = doc(db, "bookings", bookingId);
      //   const bookingSnap = await getDoc(bookingRef);

      //   if (bookingSnap.exists()) {
      //     const bookingData = bookingSnap.data();
      //     const totalResto = parseFloat(totalamount) + parseFloat(bookingData.restoamount || 0);
      //     const totalBill = parseFloat(totalamount) + parseFloat(bookingData.totalamount || 0);
      //     const totalPaid = parseFloat(paid) + parseFloat(bookingData.advance || 0);
      //     const totalRemaining = totalBill - totalPaid;

      //     await updateDoc(doc(db, 'bookings', bookingId), {
      //       restoamount: totalResto,
      //       totalamount: totalBill,
      //       advance: totalPaid,
      //       remaining: totalRemaining,
      //     });
      //   }
      // }


      // // Reset fields and refresh data
      // setsubtotal(0); setpaid(0); setremaining(0); setorderDocid("");
      // setcustid(""); setbillno(""); setpaytype("Cash"); setorderitems([]);
      // setbookingId(""); settotalamount(0);
      // fetchData1();
      // setIsLoading(false)

      let descreption = 'Save Bill clicked and Printed Bill,  Service Charge: ' + servicecharge + ', Tip: ' + tip +
        ', Total Amount: ' + parseFloat(totalamount) || 0 + ', Discount: ' + parseFloat(discount) || 0 + ', Paid: ' + parseFloat(paid) || 0 +
        ', Remaining: ' + parseFloat(remaining) || 0 + ', Pay Type: ' + paytype || null + ', Bill Type: ' + billtype || null;

      await addDoc(collection(db, 'watchreport'), {
        orderid: orderDocid || null,
        descreption: descreption,
        uid: userData.uid,
        created: Timestamp.now(),
      });

      saveOrderItems1();
      Toast.fire({ icon: "success", title: 'Order Successfully Saved' });
      // window.location.href = "/table-orders";

    } catch (error) {
      setIsLoading(false)
      Toast.fire({ icon: "error", title: 'Something went wrong. Please try again.' });
      console.log(error.message);
    }
  };


  const handleSelectItem3 = async (item) => {
    setbookingData(item);
    setbookingId(item.id);
    setcustmobile(item.data.customers.mobile);
  };

  const handleRemoveItem = async () => {
    if (!removeNote) {
      Toast.fire({ icon: "error", title: 'Please enter note' });
      return
    }

    let itemname = "";
    if (deleteItems[1]) {
      const docProdRef = doc(db, 'products', deleteItems[1]);
      const docSnap = await getDoc(docProdRef);
      itemname = docSnap.data().prodname;
    }
    else {
      itemname = "";
    }

    const docProdRef1 = doc(db, 'seatings', deleteItems[3]);
    const docSnap1 = await getDoc(docProdRef1);

    await addDoc(collection(db, 'rejectionnote'), {
      removeNote: removeNote || null,
      docid: deleteItems[0],
      itemid: deleteItems[1],
      quantity: deleteItems[2],
      tableid: deleteItems[3],
      tablebillno: deleteItems[4],
      orderid: deleteItems[5],
      itemname: itemname,
      tableno: docSnap1.data().seatname,
      uid: userData.uid,
      created: Timestamp.now()
    });

    let descreption = 'Order Item Removed ' + itemname + ', Quantity ' + deleteItems[2] + ', Note ' + removeNote;

    await addDoc(collection(db, 'watchreport'), {
      orderid: deleteItems[5] || null,
      descreption: descreption,
      uid: userData.uid,
      created: Timestamp.now(),
    });

    const taskDocRef = doc(db, 'orderitems', deleteItems[0])
    await deleteDoc(taskDocRef);
    const q1 = query(collection(db, 'orderitems'), where("orderid", "==", deleteItems[5]));
    onSnapshot(q1, async (querySnapshot) => {
      if (querySnapshot.size === 0) {
        const taskDoc2Ref = doc(db, 'orders', deleteItems[5])
        await deleteDoc(taskDoc2Ref);
        setorderDocid("")
      }
    });


    fetchData1()
    fetchOrderItems(deleteItems[3], deleteItems[4], deleteItems[5]);
    Toast.fire({ icon: "success", title: 'Order Items Sucessfully Removed' });

  }

  const handleUpdateItem = async () => {
    try {
      const isRemoving = updateQtys[0] === "Remove";
      const itemDocRef = doc(db, 'orderitems', updateQtys[1]);

      // Validation for Remove action
      if (isRemoving && parseFloat(newQty) > parseFloat(updateQtys[3])) {
        Toast.fire({ icon: "error", title: `Item Quantity should not be greater than ${updateQtys[3]}` });
        return;
      }

      if (isRemoving && !removeNote) {
        Toast.fire({ icon: "error", title: 'Please enter a note' });
        return;
      }

      const docSnap = await getDoc(itemDocRef);
      if (!docSnap.exists()) {
        Toast.fire({ icon: "error", title: 'Item does not exist' });
        return;
      }

      const currentQty = parseFloat(docSnap.data().quantity);
      let newQtyValue = isRemoving ? currentQty - parseFloat(newQty) : currentQty + parseFloat(newQty);

      const batch = writeBatch(db);

      // If new stock quantity is zero or less, delete the item and associated order
      if (newQtyValue < 1) {
        batch.update(itemDocRef, {
          quantity: newQtyValue,
          propid: removeNote || null,
          total: 0,
        }); // Delete order item
        // batch.delete(doc(db, 'orders', updateQtys[6])); // Delete order
        // setorderDocid(""); // Clear orderDocid state
      } else {
        const totalUpdated = newQtyValue * parseFloat(updateQtys[7]);

        // Update order item quantity and total
        batch.update(itemDocRef, {
          quantity: newQtyValue,
          propid: removeNote || null,
          total: totalUpdated,
          cancleby: cancleby
        });



        // Mark item as complete if necessary
        if (itemReady === 'Yes') {
          batch.update(itemDocRef, { complete: parseFloat(updateQtys[3]) });
        }
      }

      await batch.commit();

      let itemname = docSnap.data().itemname;
      let descreption = 'Order Item Updated ' + itemname + ', Quantity ' + newQtyValue + ', Note ' + removeNote;
      await addDoc(collection(db, 'watchreport'), {
        orderid: orderDocid || null,
        descreption: descreption,
        uid: userData.uid,
        created: Timestamp.now(),
      });

      fetchData1();
      fetchOrderItems(tableid, tablebillno, updateQtys[6]);

      // Reset state
      setremoveNote("");
      setupdateQtys([]);
      setitemReady("Yes");
      setnewQty(0);

      Toast.fire({ icon: "success", title: 'Order Items Successfully Updated' });

    } catch (error) {
      console.error("Error updating item: ", error.message);
    }
  };



  const calOpenFood = () => {
    let total = parseFloat(openfoodqty) * parseFloat(openfoodrate)
    setopenfoodtotal(total)
  }

  const handleServiceCharge = (e) => {
    setservicecharge(e.target.value);
    if (e.target.value) {
      let total = parseFloat(subtotal) + parseFloat(tax) + parseFloat(tip) + parseFloat(e.target.value);
      settotalamount(parseFloat(total).toFixed());
      let rem = parseFloat(total) - parseFloat(paid) - parseFloat(discount);
      setremaining(rem.toFixed(2))
    }
  }

  const handletip = (e) => {
    settip(e.target.value);
    if (e.target.value) {
      let total = parseFloat(subtotal) + parseFloat(tax) + parseFloat(servicecharge) + parseFloat(e.target.value);
      settotalamount(total.toFixed());
      let rem = parseFloat(total) - parseFloat(paid) - parseFloat(discount);
      setremaining(rem.toFixed(2))
    }
  }

  const handleDiscount = (e) => {
    setdiscount(e.target.value);
    if (e.target.value) {
      let total = parseFloat(totalamount) - parseFloat(e.target.value);
      let rem = parseFloat(total) - parseFloat(paid);
      setremaining(rem.toFixed(2))
    }
  }

  const handlePaid = (e) => {
    setpaid(e.target.value);
    if (e.target.value) {
      let total = parseFloat(totalamount) - parseFloat(discount) - parseFloat(e.target.value);
      setremaining(total.toFixed(2))
    }
  }

  const addOpenFood = async () => {
    if (!openfoodname || !openfoodqty || !openfoodrate || !openfoodtotal) {
      Toast.fire({ icon: "error", title: 'Please fill all the fields' });
      return;
    }

    try {
      let orderRef = orderDocid ? doc(db, 'orders', orderDocid) : null;

      // If no orderDocId exists, create a new order and get its reference
      if (!orderRef) {
        orderRef = await addDoc(collection(db, 'orders'), {
          bookingId: bookingId || null,
          tableid: tableid || null,
          nofopax: nofopax || null,
          custid: custid || null,
          staffid: staffid || null,
          customer: customer || null,
          staff: staff || null,
          orderdate: orderdate || null,
          tablebillno: tablebillno || null,
          billno: billno || null,
          subtotal: parseFloat(subtotal) || null,
          tax: parseFloat(tax) || null,
          servicecharge: parseFloat(servicecharge) || null,
          totalamount: parseFloat(totalamount) || null,
          discount: parseFloat(discount) || null,
          paid: parseFloat(paid) || null,
          remaining: parseFloat(remaining) || null,
          paytype: paytype || null,
          billtype: billtype || null,
          note: note || null,
          status: 'Booked',
          uid: userData.uid,
          created: Timestamp.now()
        });
        setorderDocid(orderRef.id); // Save the order ID for future updates
      }

      // Check if the food item already exists in orderitems
      const existingFoodQuery = query(
        collection(db, 'orderitems'),
        where('itemname', '==', openfoodname),
        where('orderid', '==', orderRef.id)
      );

      const existingFoodSnapshot = await getDocs(existingFoodQuery);

      const batch = writeBatch(db); // Initialize Firestore batch for updating or adding documents

      if (existingFoodSnapshot.empty) {
        // Add new food item if not exists
        const newItem = {
          orderid: orderRef.id,
          tableid: tableid || null,
          nofopax: nofopax || null,
          bookingId: bookingId || null,
          custid: custid || null,
          staffid: staffid || null,
          customer: customer || null,
          staff: staff || null,
          orderdate: orderdate || null,
          itemdesc: itemdesc || null,
          tablebillno: tablebillno || null,
          billno: billno || null,
          itemname: openfoodname || null,
          kot: "YES" || null,
          status: 'Pending',
          complete: 0,
          quantity: openfoodqty || 1,
          rate: openfoodrate || null,
          total: parseFloat(openfoodtotal),
          taxamount: 0,
          taxaddedamount: parseFloat(openfoodtotal),
          uid: userData.uid,
          created: Timestamp.now()
        };

        batch.set(doc(collection(db, 'orderitems')), newItem);

        let descreption = 'New Open Food  Added ' + openfoodname + ', Quantity ' + openfoodqty + ', Rate ' + openfoodrate;
        await addDoc(collection(db, 'watchreport'), {
          orderid: orderDocid || null,
          descreption: descreption,
          uid: userData.uid,
          created: Timestamp.now(),
        });


      } else {
        // Update existing food item quantity and total
        const existingItem = existingFoodSnapshot.docs[0];
        const updatedQuantity = parseFloat(existingItem.data().quantity) + 1;
        const updatedTotal = parseFloat(updatedQuantity) * parseFloat(openfoodrate);
        const updateData = {
          quantity: updatedQuantity,
          total: updatedTotal.toFixed(2),
          taxaddedamount: updatedTotal.toFixed(2), // Update taxaddedamount accordingly
        };

        batch.update(existingItem.ref, updateData);

        let descreption = ' Open Food  Updated ' + existingItem.data().itemname + ', Quantity ' + existingItem.data().quantity;
        await addDoc(collection(db, 'watchreport'), {
          orderid: orderDocid || null,
          descreption: descreption,
          uid: userData.uid,
          created: Timestamp.now(),
        });
      }

      // Commit batch operation for updating or adding items
      await batch.commit();

      // Clear form fields after the operation
      setopenfoodname("");
      setopenfoodqty(0);
      setopenfoodrate(0);
      setopenfoodtotal(0);

      // Fetch updated order items and data
      fetchOrderItems(tableid, tablebillno, orderRef.id);
      fetchData1(); // Refresh order data

    } catch (error) {
      console.log("Error adding food: ", error.message);
    }
  };

  const addRemark = async () => {
    await updateDoc(doc(db, 'orderitems', itemid), {
      itemdesc: itemdesc,
    });

    let descreption = 'Item Remark Added ' + itemdesc;
    await addDoc(collection(db, 'watchreport'), {
      orderid: orderDocid || null,
      descreption: descreption,
      uid: userData.uid,
      created: Timestamp.now(),
    });

    Toast.fire({ icon: "success", title: 'Item Remark Successfully Added' });
    setitemdesc("");
    fetchData1()

  }

  const saveOrderItems = async () => {
    try {
      const q = query(collection(db, "orderitems"), where("orderid", "==", orderDocid), orderBy('created', 'asc'));
      const querySnapshot = await getDocs(q);
      const orderItems = [];
      const printItems = [];
      let sum = 0;
      let tax = 0;

      // Initialize a write batch
      const batch = writeBatch(db);

      // Process data and update in a single loop
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const total = data.total ? data.total : 0;
        const taxAmount = data.taxamount ? data.taxamount : 0;
        const qty = parseFloat(data.quantity) - parseFloat(data.complete);

        sum += total;
        tax += taxAmount;

        // Push each item to the orderItems array
        orderItems.push({ id: doc.id, data });

        // Add to printItems if criteria are met
        if (data.kot === 'YES' && qty > 0) {
          printItems.push({
            id: doc.id,
            data,
            qty,
          });
        }

        batch.update(doc.ref, {
          complete: data.quantity,  // update `complete` with the quantity
          status: 'Active',  // update `status` field
        });
      });

      let descreption = 'Order Items Saved and KOT Printed';
      await addDoc(collection(db, 'watchreport'), {
        orderid: orderDocid || null,
        descreption: descreption,
        uid: userData.uid,
        created: Timestamp.now(),
      });

      // Update orderItems and printItems state only once
      setorderitems(orderItems);
      setprintorderitems(printItems);

      // Calculate totals and tax only once
      settax(parseFloat(tax));
      setsubtotal(parseFloat(sum));

      // Compute total amount and roundoff
      const totalAmount = parseFloat(sum) + parseFloat(tax) + parseFloat(servicecharge) + parseFloat(tip);
      const roundoff = parseFloat(totalAmount.toFixed() - totalAmount.toFixed(2));
      setroundoff(parseFloat(roundoff).toFixed(2));
      settotalamount(totalAmount + roundoff);
      finaltotal(0);

      await batch.commit();
      fetchData1();
      handleKotPrint();
    } catch (error) {
      console.error("Error saving order items:", error);
    }
  };


  const saveOrderItems1 = async () => {
    try {
      const updateData = {
        status: 'Bill',
      };
      const q = query(collection(db, "orderitems"), where("orderid", "==", orderDocid));
      const querySnapshot = await getDocs(q);
      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        batch.update(doc.ref, updateData);
      });
      await batch.commit();
      fetchData1();
      fetchOrderItems(tableid, 0, orderDocid);
      handlePrint();
      // Toast.fire({ icon: "success", title: 'Order Successfully Saved' });
    } catch (error) {
    }

  }

  const cancleOrder = async () => {
    await updateDoc(doc(db, 'orders', orderDocid), {
      status: "Cancled",
      note: removeNote
    });

    fetchData1()
    setorderitems([]);
    settablebillno("");
    settableid("");
    settablename("");
    setorderDocid("");
    setbillno("");
    setrevrem(0);
    setsubtotal(0);
    settax(0);
    setservicecharge(0)
    settip(0)
    settotalamount(0);
    setnofopax(0);

    let descreption = 'Order Cancled  ';
    await addDoc(collection(db, 'watchreport'), {
      orderid: orderDocid || null,
      descreption: descreption,
      uid: userData.uid,
      created: Timestamp.now(),
    });

    Toast.fire({ icon: "success", title: 'Order Successfully Cancled' });
    window.location.href = ""
  }

  return (
    <div>
      {isLoading && (
        <div style={overlayStyle}>
          <ClipLoader color="#36d7b7" size={60} />
        </div>
      )}
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <button className="btn btn-secondary " style={{ float: "right", marginTop: 4 }} >|| Table Orders  ||</button><br /><br />
            <div className="card card-primary card-outline mt-1">
              <div className="card-body table-responsive p-3" >
                {
                  seatingCat.map((item1, index) =>
                    <div key={index} >
                      <h6 style={{ textTransform: "uppercase", marginLeft: 10 }}><b>{item1.data.catname}</b></h6>
                      <div className='row'>
                        {
                          seatings.map((item, index) =>
                            <>
                              {
                                item1.id === item.data.seatcatid ?
                                  <div key={index} className='col-sm-2'>
                                    {
                                      item.status === 'Booked' ?
                                        <>
                                          {
                                            item.orders.map((item1, index) =>
                                              <button key={index} className='tableboxred'
                                                onClick={() => addTableId1(item.id, item.data.seatname, item1.data.tablebillno,
                                                  item1.data.billno, item1.id, item1.data.custid, item1.data.customer, item1.data.staffid, item1.data.staff, item1.data.nofopax)} data-toggle="modal" data-target="#addorder-modal">
                                                <h6 className='tablename'><b>{item.data.seatname}</b></h6>
                                              </button>
                                            )
                                          }

                                        </>
                                        :
                                        <button key={index} className='tableboxgreen' onClick={() => addTableId(item.id, item.data.seatname)} data-toggle="modal" data-target="#addorder-modal">
                                          <h6 className='tablename'><b>{item.data.seatname}</b></h6>
                                        </button>
                                    }
                                  </div> : null
                              }
                            </>
                          )
                        }
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </section>
      </div >

      <div className="modal fade" id="addorder-modal">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: "#F9F7F7", padding: 10 }}>
              <h5 className="modal-title" style={{ color: "black" }}>{tablename} Table</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
              <div style={{ float: "right" }}>
                <button className=' btn btn-info btn-danger' data-toggle="modal" data-target="#cancle-modal">Cancle Order </button>&nbsp;
                {/* <button onClick={saveOrderItems} className=' btn btn-info btn-default'>Save </button>&nbsp; */}
                <button onClick={saveOrderItems} className=' btn btn-info'>KOT Print  <i className='fa fa-print'></i> </button>&nbsp;
                {/* <button onClick={saveOrderItems1} className=' btn bg-purple'>Preview Bill   </button>&nbsp; */}
                {/* <button onClick={() => finaltotal(0)} data-toggle="modal" data-target="#bill-modal" className=' btn btn-success'>Settlement</button>&nbsp; */}
                <button className=' btn btn-warning' data-dismiss="modal" aria-label="Close">Home  </button>&nbsp;
              </div>
            </div>
            <div className="modal-body p-1">
              <div className='row'>
                <div className='col-sm-2 mobile-hide'>
                  <div className='card' style={{ height: "100%", backgroundColor: "#F9F7F7" }}>
                    <div className='card-body'>
                      {
                        productCat.map((item, index) =>
                          <button key={index} onClick={() => fetchProducts(item.id)} style={{ textTransform: "uppercase", textAlign: "left", fontSize: 14 }} className='form-control btn btn-info mt-1'>{item.data.catname}</button>
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='card' style={{ height: "100%", backgroundColor: "#F9F7F7" }}>
                    <div className='card-body'>
                      <div className='row'>
                        {/* <div className='col-sm-5'>
                          <Select options={customers} value={customer} onChange={handleSelectItem1} />
                          <label>Outstanding Amount : <span style={{ color: "red" }}> Rs.{revrem}/- </span></label>
                        </div> */}
                        <div className='col-sm-6'>
                          <label>Select Staff</label>
                          <Select options={staffs} value={staff} onChange={handleSelectItem2} />
                        </div>
                        <div className='col-sm-6'>
                          <label>No Of Person</label>
                          <input type="text" className='form-control' value={nofopax} onChange={(e) => setnofopax(e.target.value)} />
                        </div>
                        {/* <div className='col-sm-2'>
                          <button className="btn btn-info btn-xs" style={{ float: "right" }} data-toggle="modal" data-target="#customer-modal">Add  Credit Account</button><br />
                        </div> */}
                      </div><hr />

                      <div className="row">
                        <div className="col-sm-3">
                          <label> Open Food Name</label>
                          <input type="text" value={openfoodname} onChange={(e) => setopenfoodname(e.target.value)} className="form-control" />
                        </div>
                        <div className="col-sm-2">
                          <label>Rate</label>
                          <input type="text" value={openfoodrate} onChange={(e) => setopenfoodrate(e.target.value)} className="form-control" onKeyUp={calOpenFood} />
                        </div>
                        <div className="col-sm-2">
                          <label>Quantity</label>
                          <input type="number" value={openfoodqty} onChange={(e) => setopenfoodqty(e.target.value)} className="form-control" onKeyUp={calOpenFood} />
                        </div>
                        <div className="col-sm-3">
                          <label>Total</label>
                          <input type="text" value={openfoodtotal} onChange={(e) => setopenfoodtotal(e.target.value)} className="form-control" onKeyUp={calOpenFood} />
                        </div>
                        <div className="col-sm-2">
                          <br />
                          <button className='btn btn-success btn-sm form-control mt-2' onClick={addOpenFood}>Add </button>
                        </div>
                      </div><br />
                      <div className='row'>
                        <div className='col-sm-12'>
                          <label>Search Product</label>
                          <Select options={products} value={item} onChange={handleSelectItem} /><hr />
                        </div>
                      </div>
                      <div className='row mobile-hide'>
                        {
                          products.map((item, index) =>
                            <div key={index} className='col-sm-3'>
                              <button onClick={() => addOrderItems(item, item.id, item.data.rate)} style={{ fontSize: 11, padding: 0 }} className='form-control btn btn-secondary mt-1'> {item.data.prodname} {item.unit}</button>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-4'>
                  <div className='card' style={{ height: "100%" }}>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <label>Bill No : {billno}</label>
                        </div>
                        <div className='col-sm-6'>
                          <label style={{ float: "right" }}>Date No : {moment(orderdate).format('DD/MM/YYYY')}</label>
                        </div>
                      </div>
                      <div className='productBox '>
                        <table className='customers3'>
                          {
                            orderitems.map((item, index) =>
                              <tr key={index}>
                                <td>
                                  <a href='#0' data-toggle="modal" data-target="#itemdesc-modal" onClick={() => setitemid(item.id)}>
                                    {item.data.itemname} <br /> <span style={{ color: "#454545" }}>{item.data.itemdesc}</span>
                                  </a>
                                </td>
                                <td>
                                  {
                                    item.data.status === 'Active' || item.data.status === 'Bill' ?
                                      <button data-toggle="modal" data-target="#qtyupdate-modal" onClick={() => setupdateQtys(['Remove', item.id, item.data.itemid, 1, item.data.tableid, item.data.tablebillno, item.data.orderid, item.data.rate])} className='btn btn-xs btn-info'><i className='fa fa-minus'></i></button> :
                                      <button onClick={() => upOrderQty('Remove', item.id, item.data.itemid, 1, item.data.tableid, item.data.tablebillno, item.data.orderid, item.data.rate)} className='btn btn-xs btn-info'><i className='fa fa-minus'></i></button>
                                  }
                                </td>
                                <td>{item.data.quantity}</td>
                                <td style={{ textAlign: "right" }}><button onClick={() => upOrderQty('Add', item.id, item.data.itemid, 1, item.data.tableid, item.data.tablebillno, item.data.orderid, item.data.rate)} className='btn btn-xs btn-info'><i className='fa fa-plus'></i></button></td>
                                <td style={{ textAlign: "right" }}>{parseFloat(item.data.total).toFixed(2)}</td>
                                {
                                  item.data.status === 'Active' || item.data.status === 'Bill' ?
                                    <td style={{ textAlign: "right" }}><button data-toggle="modal" data-target="#reject-modal" onClick={() => deleteOrderItem(item.id, item.data.itemid, item.data.quantity, item.data.tableid, item.data.tablebillno, item.data.orderid, item.data.rate, item.data.status)} className='btn btn-xs btn-danger'><i className='fa fa-trash'></i></button></td> :
                                    <td style={{ textAlign: "right" }}><button onClick={() => deleteOrderItem(item.id, item.data.itemid, item.data.quantity, item.data.tableid, item.data.tablebillno, item.data.orderid, item.data.rate, item.data.status)} className='btn btn-xs btn-danger'><i className='fa fa-trash'></i></button></td>
                                }
                              </tr>
                            )
                          }
                        </table>

                      </div><hr />
                      <table width="100%">
                        <tr>
                          <td width="30%">
                            <h6 style={{ marginBottom: 10 }}>Sub Total  </h6>
                            <h6 style={{ marginBottom: 10 }}>Tax  </h6>
                            <h6 style={{ marginBottom: 10 }}>Round Off  </h6>
                            <h6 style={{ marginBottom: 10 }}>Total Amount  </h6>
                          </td>
                          <td width="40%">
                            <center>
                              <button onClick={() => finaltotal(0)} data-toggle="modal" data-target="#bill-modal" className=' form-control btn btn-sm btn-success'>Create Bill</button>
                              <button style={{ marginTop: 15 }} onClick={() => finaltotal(1)} data-toggle="modal" data-target="#bill-modal" className='form-control btn btn-sm btn-default bg-pink'>Post Bill</button>
                            </center>
                          </td>
                          <td width="30%">
                            <h6 style={{ marginBottom: 10, textAlign: "right" }}> Rs. {parseFloat(subtotal)} </h6>
                            <h6 style={{ marginBottom: 10, textAlign: "right" }}> Rs. {parseFloat(tax)} </h6>
                            <h6 style={{ marginBottom: 10, textAlign: "right" }}> Rs. {parseFloat(roundoff)} </h6>
                            <h6 style={{ marginBottom: 10, textAlign: "right" }}> Rs. {parseFloat(totalamount)} </h6>
                          </td>
                        </tr>
                      </table>
                      <hr />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="bill-modal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Create Bill {tablename}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#customer-modal">Create New Credit Account</button><hr />
              <div className='row '>
                <div className="col-sm-3">
                  <label>Bill Type </label>
                  <select className='form-control' style={{ height: 35, fontSize: 14 }} value={billtype} onChange={(e) => setbilltype(e.target.value)}>
                    {
                      billtype === "Room Posting" &&
                      <>
                        <option value="Room Posting">Room Posting</option>
                        <option value="Credit Account">Credit Account</option>
                      </>
                    }
                    {
                      billtype === "Regular Bill" &&
                      <>
                        <option value="Regular Bill">Regular Bill</option>
                        <option value="Credit Account">Credit Account</option>
                      </>
                    }
                    {
                      billtype === "Credit Account" &&
                      <>
                        <option value="Credit Account">Credit Account</option>
                      </>
                    }
                  </select>
                </div>
                {
                  billtype === 'Credit Account' ?
                    <div className='col-sm-3'>
                      <label>Select Account </label>
                      <Select options={customers} value={customer} onChange={handleSelectItem1} />
                    </div>
                    :
                    billtype === 'Room Posting' ?
                      <>
                        <div className='col-sm-3'>
                          <label>Select Room </label>
                          <Select options={bookings} value={bookingData} onChange={handleSelectItem3} />
                        </div>
                        <div className='col-sm-3'>
                          <label>Mobile No </label>
                          <input className='form-control' value={custmobile} readOnly />
                        </div>
                      </>

                      : null
                }
              </div><br />
              <div className='row '>
                <div className="col-sm-3">
                  <label>Bill No </label>
                  <input type="text" className="form-control" style={{ height: 35, fontSize: 14 }} value={billno} onChange={(e) => setbillno(e.target.value)} />
                </div>
                <div className="col-sm-3">
                  <label>Bill Date </label>
                  <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={orderdate} onChange={(e) => setorderdate(e.target.value)} />
                </div>
                <div className="col-sm-3">
                  <label>Note </label>
                  <textarea className="form-control" value={note} onChange={(e) => setnote(e.target.value)} style={{ height: 35, fontSize: 14 }}></textarea>
                </div>
              </div><br />
              <div className='row '>
                <div className='col-sm-3'>
                  <label>Sub Total </label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} readOnly value={subtotal} />
                </div>
                <div className='col-sm-3'>
                  <label>Tax </label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} readOnly value={tax} />
                </div>
                <div className='col-sm-3'>
                  <label>Service Charge </label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} value={servicecharge} onChange={handleServiceCharge} />
                </div>
                <div className='col-sm-3'>
                  <label>Tip </label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} value={tip} onChange={handletip} />
                </div>
                <div className='col-sm-3'>
                  <label>Total Amount </label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} readOnly value={totalamount} />
                </div>
                <div className="col-sm-3">
                  <label>Discount</label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} const value={discount} onChange={handleDiscount} placeholder='Enter Paid Amount' />
                </div>
                <div className="col-sm-3">
                  <label>Paid</label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} value={paid} onChange={handlePaid} placeholder='Enter Paid Amount' />
                </div>
                <div className="col-sm-3">
                  <label>Payment Type</label>
                  <select className='form-control' style={{ height: 35, fontSize: 14 }} value={paytype} onChange={(e) => setpaytype(e.target.value)}>
                    <option></option>
                    {
                      paymodes.map((item, index) =>
                        <option key={index} value={item.data.paymode}>{item.data.paymode}</option>
                      )
                    }
                  </select>
                </div>
                <div className="col-sm-3">
                  {
                    remaining < 0 ?
                      <label>Return</label> :
                      <label>Remaining</label>
                  }
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} value={remaining} readOnly />
                </div>
              </div><br></br>
              <center>
                <button onClick={() => saveBill1()} className='btn btn-info'>Save Bill</button>&nbsp;
                <button onClick={() => saveBill()} className='btn btn-success'>Final Bill</button>
              </center>
              <hr />
              <table className='table table-bordered'>
                <tr>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Rate</th>
                  <th>Tax</th>
                  <th>Total</th>
                </tr>
                {
                  orderitems.map((item, index) =>
                    <tr key={index}>
                      <td>{item.data.itemname}  </td>
                      <td>{item.data.quantity}</td>
                      <td>{item.data.rate}</td>
                      <td>{item.data.taxamount}</td>
                      <td>{item.data.taxaddedamount}/-</td>
                    </tr>
                  )
                }
              </table>


            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="customer-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Credit Account</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6">
                  <label>  Account Name</label>
                  <input type="text" value={name} onChange={(e) => setname(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6">
                  <label>Mobile</label>
                  <input type="text" value={mobile} onChange={(e) => setmobile(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>Address</label>
                  <input type="text" value={address} onChange={(e) => setaddress(e.target.value)} className="form-control" />
                </div>
              </div><br />
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Save </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="bill-modal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Print Bill {tablename}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div style={{ padding: 20 }} ref={printRef}>
                <div className='col-sm-4'>
                  <center>
                    <h4 style={{ textTransform: "uppercase" }}>THE SKYLAND HOTEL & RESTAURANT  </h4>
                    <span style={{ fontSize: 15, color: "black" }}>Near New Bus Stand, Latur Road, Tuljapur, Dist. Dharashiv, 413 601</span><br />
                    <span style={{ fontSize: 15, color: "black" }}>Mobile No: 7077779163, 7077779164, 7077779165 </span><br />
                    <span style={{ fontSize: 15, color: "black" }}>GSTIN No: 27AAWFP8677Q1ZN </span><br />
                  </center>
                  <br />
                  <table width="100%">
                    <tr>
                      <td style={{ color: "black", fontSize: 15 }}>Bill No : {billno} </td>
                      <td style={{ textAlign: "right", color: "black", fontSize: 15 }}>Table No : {tablename}</td>
                    </tr>
                    <tr >
                      <td style={{ color: "black", fontSize: 15 }}>Date :  {moment(orderdate).format('DD/MM/YYYY')} </td>
                      <td style={{ textAlign: "right", color: "black", fontSize: 15 }}>Time :  {moment().format('HH:mm:ss A')} </td>
                    </tr>
                    <tr >
                      <td colSpan={2} style={{ color: "black", fontSize: 15 }}>No of Person :  {nofopax} </td>
                    </tr>
                    <tr >
                      <td style={{ color: "black", fontSize: 15 }}>Guest Name  :  {customer.name} </td>
                      <td style={{ textAlign: "right", color: "black", fontSize: 15 }}>Mobile  :  {customer.mobile} </td>
                    </tr>
                  </table>
                  <table className='billtables' width="100%" style={{ marginTop: 2 }}>
                    <tr>
                      <th>Item Name</th>
                      <th style={{ fontSize: 15, color: "black", textAlign: "right" }}>Qty</th>
                      <th style={{ fontSize: 15, color: "black", textAlign: "right" }}>Rate</th>
                      <th style={{ fontSize: 15, color: "black", textAlign: "right" }}>Total</th>
                    </tr>
                    {
                      orderitems.map((item, index) =>
                        <tr key={index}>
                          <td>{item.data.itemname}  </td>
                          <td style={{ fontSize: 15, color: "black", textAlign: "right" }}>{item.data.quantity}</td>
                          <td style={{ fontSize: 15, color: "black", textAlign: "right" }}>{item.data.rate}</td>
                          <td style={{ fontSize: 15, color: "black", textAlign: "right" }}>{parseFloat(item.data.total).toFixed(2)}</td>
                        </tr>
                      )
                    }
                  </table>
                  <h5 style={{ fontSize: 15, marginBottom: 10, marginTop: 5 }}>Sub Total   <span style={{ float: "right" }}> {subtotal}</span> </h5>
                  <h5 style={{ fontSize: 15, marginBottom: 10 }}>Cgst (2.5%)   <span style={{ float: "right" }}> {parseFloat(tax / 2)}</span> </h5>
                  <h5 style={{ fontSize: 15, marginBottom: 10 }}>Sgst (2.5%)   <span style={{ float: "right" }}> {parseFloat(tax / 2)}</span> </h5>
                  <h5 style={{ fontSize: 15, marginBottom: 10 }}>Round Off    <span style={{ float: "right" }}> {parseFloat(roundoff)}</span> </h5>
                  {/* <h5 style={{ fontSize: 15,marginBottom: 10 }}>Service Charge + Tip    <span style={{ float: "right" }}> {parseFloat(servicecharge) + parseFloat(tip)}</span> </h5> */}
                  <h5 style={{ fontSize: 15, marginBottom: 10 }}>Total Amount   <span style={{ float: "right" }}> {totalamount}</span> </h5>
                  <hr />
                  <center>
                    <h6>Thank You</h6>
                  </center>
                  <span style={{ fontSize: 14, color: "black" }}>Prepared By  : {username}</span><br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="bill-modal1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">KOT PRINT {tablename}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              {/* ==================================== Print Bill ==================================== */}

              <div style={{ padding: 20 }} ref={printRef1}>
                <div className='col-sm-4'>
                  <center>
                    <h4 style={{ textTransform: "uppercase", fontWeight: "700" }}>THE SKYLAND HOTEL & RESTAURANT  </h4>
                  </center>
                  <table width="100%">
                    <tr>
                      <td style={{ color: "black", fontSize: 16 }}>Bill No : {billno} </td>
                      <td style={{ textAlign: "right", color: "black", fontSize: 16 }}>Table No : {tablename}</td>
                    </tr>
                    <tr >
                      <td style={{ color: "black", fontSize: 16 }}>Date :  {moment(orderdate).format('DD/MM/YYYY')} </td>
                      <td style={{ textAlign: "right", color: "black", fontSize: 16 }}>Time :  {moment().format('HH:mm:ss A')} </td>
                    </tr>
                    <tr >
                      <td colSpan={2} style={{ color: "black", fontSize: 16 }}>No of Person :  {nofopax} </td>
                    </tr>
                    <tr >
                      <td colSpan={2} style={{ color: "black", fontSize: 16 }}>Printed By :  {username} </td>
                    </tr>
                  </table>
                  <table width="100%" className='billtables' style={{ marginTop: 2 }}>
                    <tr>
                      <th style={{ fontSize: 18 }}>Item Name</th>
                      <th style={{ textAlign: "right", fontSize: 18 }}>Qty</th>
                    </tr>
                  </table>
                  <table width="100%">
                    {
                      printorderitems.map((item, index) =>
                        <tr key={index}>
                          <td style={{ fontSize: 20 }}>{item.data.itemname} <br /> <i style={{ color: "#454545" }}>{item.data.itemdesc}</i>  </td>
                          <td style={{ textAlign: "right", fontSize: 20, verticalAlign: "top" }}>{item.qty}</td>
                        </tr>
                      )
                    }
                  </table>
                  <br />
                  <br />
                  <hr />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="reject-modal">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Enter Rejection for Remove Item</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div >
                <div className='col-sm-12'>
                  <label>Enter Note</label>
                  <input className='form-control' value={removeNote} onChange={(e) => setremoveNote(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => handleRemoveItem()} className="btn btn-primary" data-dismiss="modal">Save </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="cancle-modal">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Enter Rejection for Cancel Order</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div >
                <div className='col-sm-12'>
                  <label>Enter Note</label>
                  <input className='form-control' value={removeNote} onChange={(e) => setremoveNote(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={cancleOrder} className="btn btn-primary" data-dismiss="modal">Save </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="itemdesc-modal">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Item Remark</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div >
                <div className='col-sm-12'>
                  <label>Enter Remark</label>
                  <input className='form-control' value={itemdesc} onChange={(e) => setitemdesc(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => addRemark()} className="btn btn-primary" data-dismiss="modal">Save </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="qtyupdate-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{updateQtys[0]} Qty</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className='row'>
                <div className='col-sm-4'>
                  <label>{updateQtys[3]} Item Ready in Kitchen</label>
                  <select className='form-control' value={itemReady} onChange={(e) => setitemReady(e.target.value)}>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                <div className='col-sm-4'>
                  <label>{updateQtys[0]} Qty</label>
                  <input type='number' className='form-control' value={newQty} onChange={(e) => setnewQty(e.target.value)} />
                </div>
                {
                  updateQtys[0] === 'Remove' &&
                  <div className='col-sm-4'>
                    <label>Remove Qty Note</label>
                    <input type='text' className='form-control' value={removeNote} onChange={(e) => setremoveNote(e.target.value)} />
                  </div>
                }
                <div className='col-sm-4'>
                  <label>Select Reason</label>
                  <select className='form-control' value={cancleby} onChange={(e) => setcancleby(e.target.value)}>
                    <option value="Cancel By Customer">Cancel By Customer</option>
                    <option value="My Mistaken Added">My Mistaken Added</option>
                  </select>
                </div>
              </div>

            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => handleUpdateItem()} className="btn btn-primary" data-dismiss="modal">Save </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

    </div >
  )
}

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000, // Ensure the overlay is on top
};


export default Tableorders
