import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import { auth, db } from '../firebase';
import { collection, doc, getDoc, onSnapshot, query } from 'firebase/firestore';
import moment from 'moment';

function Header() {


    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            userDataFetch()
        });
    });

    const signOut = () => {
        auth.signOut();
        localStorage.clear();
        window.location.href = "/login";
    }

    const [username, setusername] = useState("");
    const [userfetch, setuserfetch] = useState(true);

    const userDataFetch = async () => {
        if (userfetch) {
            try {
                const docRef = doc(db, "users", auth?.currentUser.uid)
                const docSnap = await getDoc(docRef)
                if (docSnap.exists()) {
                    setusername(docSnap.data().username);
                }
            } catch (error) {
                console.log(error.message);
            }

          

        }
        setuserfetch(false)
    }

    return (
        <div>
            {/* <div className="preloader flex-column justify-content-center align-items-center">
        <img className="animation__shake" src="../dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
        </div> */}

            <nav className="main-header navbar navbar-expand navbar-info navbar-light">
                {/* Left navbar links */}
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#0" role="button"><i style={{ color: "white" }} className="fas fa-bars" /></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#0" >
                            <b style={{ color: "white", textTransform: "uppercase" }}> SKYLAND RESTAURENT </b>
                        </a>
                    </li>

                </ul>
                {/* Right navbar links */}
                <ul className="navbar-nav ml-auto">


                    {/* <li className="nav-item">
                        <a className="nav-link" data-widget="fullscreen" href="#0" role="button">
                            <i style={{color:"white"}} className="fas fa-expand-arrows-alt" />
                        </a>
                    </li> */}

                    <li className="nav-item">
                        <a className="nav-link mobvis" href="javascript:history.go(-1)">
                            <i style={{ color: "white" }} className="fas fa-backward" />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link mobvis" href="javascript:history.go(+1)">
                            <i style={{ color: "white" }} className="fas fa-forward" />
                        </a>
                    </li>


                    <li class="nav-item dropdown">
                        <a class="nav-link mobvis" data-toggle="dropdown" href="#0">
                            <i style={{ color: "white" }} className="fa fa-user-circle" />
                        </a>
                        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <a href="#0" class="dropdown-item">
                                <h3 class="dropdown-item-title">{username}</h3>
                            </a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => signOut()} href="#0">
                            <i style={{ color: "white" }} className="fa fa-sign-out" />
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
export default Header