import React, { useState } from 'react'
import '.././App.css';

export default function Planexpired() {


    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <div className='row'>
                            <div className='col-sm-3'>

                            </div>
                            <div className='col-sm-6'>
                                <div className="card card-danger card-outline ">
                                    <div className="card-body text-center" >
                                        <h4>Your reacharge plan is expired</h4>
                                        <p>To continue enjoying uninterrupted services, we recommend renewing your plan as soon as possible. Please visit the front desk or contact us  for more details about our available plans and special offers.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >

        </div >
    )
}
